import { SYSTEM_TIME } from '@op-platform/backend/project';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { OfferButton } from '@/components/proposals/offer-button';
import { OfferDialog } from '@/components/proposals/offer-dialog';
import { TimezoneSelector } from '@/components/timezone-selector';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { Option, TRPCRouterInput } from '@/lib/global-types';
import { cn } from '@/lib/utils';
import { toast } from '@/lib/utils/toast';

type CreatProjectInput = TRPCRouterInput['project']['create'];

const today = SYSTEM_TIME;

const nextMonday =
  today().day() === 1
    ? today().add(7, 'day')
    : today().add(8 - today().day(), 'day'); // Otherwise, find the next Monday

const POSSIBLE_DATES = [
  nextMonday.add(1, 'week'),
  nextMonday.add(2, 'week'),
  nextMonday.add(3, 'week'),
];

export const Route = createFileRoute('/dashboard/admin/create-project')({
  component: function Page() {
    const navigate = Route.useNavigate();
    const [payload, setPayload] = useState<
      Omit<CreatProjectInput, 'deliverable'>
    >({
      company_name: '',
      email: '',
      name: '',
      projectStartDate: '',
      stage: 'UPSTART',
      timezone: '',
    });

    const [deliverable, setDeliverable] = useState<Option>({
      id: 99999999,
      optionPackId: 99999999,
      plan: null,
      services: [],
      description: '',
      type: 'REQUESTED',
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const shouldShowSubmitButton =
      payload.name &&
      payload.email &&
      payload.company_name &&
      payload.timezone &&
      payload.stage &&
      payload.projectStartDate;

    /*     const createProjectMutation = useMutation({
      mutationFn: async () => {
        if (!shouldShowSubmitButton) {
          return;
        }

        const founder = await AdminService.createProject(payload);

        navigate({
          to: '/dashboard/admin/founders/$founderId/proposal',
          params: {
            founderId: founder.id,
          },
          replace: true,
        });
      },
    }); */

    const createProjectMutation = trpc.project.create.useMutation();

    const onCreate = async () => {
      if (!shouldShowSubmitButton) {
        return;
      }

      const project = await createProjectMutation.mutateAsync({
        company_name: payload.company_name,
        email: payload.email,
        name: payload.name,
        projectStartDate: payload.projectStartDate,
        stage: payload.stage,
        timezone: payload.timezone,
        deliverable: {
          type: deliverable.type,
          plan: deliverable.plan,
          services: deliverable.services,
        },
      });

      toast('Project created successfully');
      navigate({
        to: '/dashboard/project/$projectId',
        params: {
          projectId: project.id.toString(),
        },
        replace: true,
      });
    };

    return (
      <form
        onSubmit={e => {
          e.preventDefault();

          onCreate();
        }}>
        <div className="flex flex-col items-center gap-10 pb-40">
          <h1>Create Project</h1>
          <Input
            placeholder="Founder Name"
            value={payload.name}
            onChange={e => setPayload({ ...payload, name: e.target.value })}
          />

          <Input
            placeholder="Founder Email"
            type="email"
            value={payload.email}
            onChange={e => setPayload({ ...payload, email: e.target.value })}
          />

          <Input
            placeholder="Company Name"
            value={payload.company_name}
            onChange={e =>
              setPayload({ ...payload, company_name: e.target.value })
            }
          />

          {/*     <Textarea
            className="w-full self-center"
            placeholder="Description"
            value={payload.project_outcome_description}
            onChange={e =>
              setPayload({
                ...payload,
                project_outcome_description: e.target.value,
              })
            }
          /> */}

          {/*       <div>
            <p className="mb-2">Links</p>
            <Links
              currentLink={currentLink}
              setCurrentLink={setCurrentLink}
              linkList={payload.links}
              setLinkList={links =>
                setPayload({ ...payload, links: links as never[] })
              }
            />
          </div> */}

          <div className="h-[300px]">
            <TimezoneSelector
              placeholder="Founder Timezone"
              defaultValue={payload.timezone}
              fetchOnMount
              onChange={value => {
                setPayload({ ...payload, timezone: value });
              }}
            />
          </div>

          <OfferButton
            title="Deliverables"
            payload={deliverable}
            onClick={() => setIsDialogOpen(true)}
          />

          <OfferDialog
            open={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
              /* saveMutation.mutate(true); */
            }}
            option={deliverable}
            setOption={newOption => {
              if (newOption) {
                setDeliverable(newOption);
              }
            }}
          />

          <div>
            <p className="mb-2">Company Stage</p>
            <ToggleGroup
              type="single"
              defaultValue={payload.stage}
              onValueChange={value => {
                if (value) {
                  setPayload({
                    ...payload,
                    stage: value as CreatProjectInput['stage'],
                  });
                }
              }}>
              <ToggleGroupItem value="UPSTART">Upstart</ToggleGroupItem>
              <ToggleGroupItem value="GROWTH">Growth</ToggleGroupItem>
              <ToggleGroupItem value="LEGACY">Legacy</ToggleGroupItem>
            </ToggleGroup>
          </div>

          <div className="flex flex-col items-center gap-6">
            <h2>Starting Date</h2>
            <div className="flex gap-2">
              {POSSIBLE_DATES.map(date => (
                <Button
                  type="button"
                  key={date.toString()}
                  className={cn(
                    date.isSame(payload.projectStartDate, 'day') &&
                      'bg-primary text-white',
                  )}
                  onClick={() => {
                    setPayload({
                      ...payload,
                      projectStartDate: date.toISOString(),
                    });
                  }}>
                  <span className="w-[70%]">{date.format('MMM D ddd')}</span>
                </Button>
              ))}
            </div>
          </div>
        </div>

        <BottomNavigation
          left={<BackButton />}
          middle={
            shouldShowSubmitButton && (
              <Button
                type="submit"
                loading={createProjectMutation.isPending}
                disabled={createProjectMutation.isPending}>
                Submit
              </Button>
            )
          }
        />
      </form>
    );
  },
});
