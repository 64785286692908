import { createFileRoute, Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { helpDialog$ } from '@/components/dialogs/common/help';
import { FadeScaleContainer } from '@/components/fade-scale-container';
import { DelivarablesDialog } from '@/components/founder/deliverables-dialog';
import { OfferButton } from '@/components/proposals/offer-button';
import { SlideContainer } from '@/components/slide-container';
import { Button } from '@/components/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { Option } from '@/lib/global-types';
import { cn, isBlankOption } from '@/lib/utils';

export const Route = createFileRoute('/dashboard/founder/proposals')({
  loader: async () => {
    const founder = await trpcClientUtils.founder.me.ensureData();

    return { founder };
  },
  component: function Page() {
    const { founder } = Route.useLoaderData();

    const options = founder.optionsPack.options;
    const optionPack = founder.optionsPack;

    const requestedOption = options.find(o => o.type === 'REQUESTED')!;
    const recommendedOption = options.find(o => o.type === 'RECOMMENDED');
    const suggestedOption = options.find(o => o.type === 'SUGGESTED');

    const navigate = Route.useNavigate();

    const { isMobile } = useIsMobile();

    const [selectedOptionType, setSelectedOptionType] = useState<
      Option['type'] | null
    >(null);
    const [delivarablesDialogOpen, setDelivarablesDialogOpen] = useState(false);

    const selectedOption = options.find(o => o.type === selectedOptionType);

    const confirmMutation = trpc.founder.confirmOption.useMutation();

    const showingOptionsLength = [
      requestedOption,
      recommendedOption,
      suggestedOption,
    ].filter(o => !isBlankOption(o)).length;

    const onConfirm = async () => {
      if (!selectedOption) return;

      await confirmMutation.mutateAsync({
        optionId: selectedOption.id,
      });

      navigate({
        to: '/dashboard/founder',
      });
    };

    return (
      <>
        <FadeScaleContainer className="flex w-full flex-col items-center pb-52 text-center">
          <div className="mb-10 flex flex-col gap-2 md:mb-20">
            <h2 className="text-center">
              Which package works best for you? <br />
            </h2>

            <Link
              className="underline"
              to="/dashboard/founder/proposals/included">
              Compare what’s included
            </Link>
          </div>

          <div className="flex w-screen flex-col items-center gap-6 md:w-auto md:flex-row">
            <Carousel
              opts={{
                containScroll: isMobile ? false : 'keepSnaps',
                active: isMobile,
              }}
              className="w-full">
              <CarouselContent>
                <CarouselItem
                  className={cn(
                    'flex basis-[100%] items-center justify-center',
                    `md:basis-1/${showingOptionsLength}`,
                    { 'basis-[80%]': showingOptionsLength !== 1 },
                  )}>
                  <OfferButton
                    title={'Request'}
                    className={cn({
                      'is-active bg-black text-white':
                        selectedOptionType === 'REQUESTED',
                    })}
                    onClick={() => {
                      setSelectedOptionType('REQUESTED');
                      setDelivarablesDialogOpen(true);
                    }}
                    payload={requestedOption}
                  />
                </CarouselItem>

                {!isBlankOption(recommendedOption) && (
                  <CarouselItem
                    className={cn(
                      'flex basis-[100%] items-center justify-center',
                      `md:basis-1/${showingOptionsLength}`,
                      { 'basis-[80%]': showingOptionsLength !== 1 },
                    )}>
                    <OfferButton
                      title={'Recommendation'}
                      className={cn({
                        'is-active bg-black text-white':
                          selectedOptionType === 'RECOMMENDED',
                      })}
                      onClick={() => {
                        setSelectedOptionType('RECOMMENDED');
                        setDelivarablesDialogOpen(true);
                      }}
                      payload={recommendedOption!}
                    />
                  </CarouselItem>
                )}

                {!isBlankOption(suggestedOption) && (
                  <CarouselItem
                    className={cn(
                      'flex basis-[100%] items-center justify-center',
                      `md:basis-1/${showingOptionsLength}`,
                      { 'basis-[80%]': showingOptionsLength !== 1 },
                    )}>
                    <OfferButton
                      title={'Suggestion'}
                      className={cn({
                        'is-active bg-black text-white':
                          selectedOptionType === 'SUGGESTED',
                      })}
                      onClick={() => {
                        setSelectedOptionType('SUGGESTED');
                        setDelivarablesDialogOpen(true);
                      }}
                      payload={suggestedOption!}
                    />
                  </CarouselItem>
                )}
              </CarouselContent>
            </Carousel>
          </div>

          <div className="mt-20 flex flex-row items-center gap-4">
            <Button asChild>
              <Link target="_blank" to={`/presentation/founder`}>
                Process
              </Link>
            </Button>
            <Button asChild>
              <Link
                target="_blank"
                to={'/samples'}
                search={{ filter: undefined }}>
                Samples
              </Link>
            </Button>
          </div>

          {selectedOption && (
            <DelivarablesDialog
              name={selectedOptionType!}
              open={delivarablesDialogOpen}
              option={selectedOption}
              founder={founder}
              onConfirm={onConfirm}
              loading={confirmMutation.isPending}
              onClose={() => setDelivarablesDialogOpen(false)}
            />
          )}

          <SlideContainer
            key={selectedOptionType}
            className="mt-20 flex w-1/3 flex-col gap-20 text-center">
            <p>{optionPack.description}</p>
            <p>
              Staring date is set on <br /> {/* //Monday 15th of September. */}
              {dayjs(optionPack.startDate).format('dddd Do of MMMM')}
            </p>
          </SlideContainer>
        </FadeScaleContainer>
        <BottomNavigation
          middle={
            <Button size={'sm'} onClick={() => helpDialog$.set(true)}>
              ?
            </Button>
          }
        />
      </>
    );
  },
});
