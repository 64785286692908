import { createFileRoute, redirect } from '@tanstack/react-router';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Review } from '@/lib/global-types';
import { guardRoute } from '@/lib/route.utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute(
  '/dashboard/project/$projectId/review/$reviewNumber/rate',
)({
  beforeLoad: () => guardRoute(['ADMIN']),
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const review = project.reviews.find(
      review => review.reviewNumber === Number(params.reviewNumber),
    );

    if (!review) {
      toast("Couldn't find the Review");
      throw redirect({
        to: '/dashboard/project/$projectId',
        params: { projectId: project.id.toString() },
        throw: true,
        replace: true,
      });
    }

    return { project, review };
  },
  component: RouteComponent,
});
function RouteComponent() {
  const { review, project } = Route.useLoaderData();

  const reviewMutation = trpc.project.review.rate.useMutation();
  const navigate = Route.useNavigate();

  const onRate = async (rate: Review['rating']) => {
    if (!rate) return;
    await reviewMutation.mutateAsync({
      reviewId: review.id,
      id: project.id,
      rating: rate,
    });

    navigate({
      to: '/dashboard/project/$projectId/review/$reviewNumber/feedback',
      params: {
        projectId: project.id.toString(),
        reviewNumber: String(review.reviewNumber),
      },
      replace: true,
    });
  };

  return (
    <>
      <div className="flex flex-col items-center gap-20">
        <h1>How does this review feel?</h1>

        <div className="flex items-center gap-4">
          <Button
            onClick={() => onRate('ON_TRACK')}
            loading={reviewMutation.isPending}>
            <div className="flex flex-col items-center gap-4">
              <div className="size-2 rounded-full bg-green"></div>
              <div>On-track</div>
            </div>
          </Button>
          <Button
            onClick={() => onRate('ADJUST')}
            loading={reviewMutation.isPending}>
            <div className="flex flex-col items-center gap-4">
              <div className="size-2 rounded-full bg-yellow"></div>
              <div>Adjust</div>
            </div>
          </Button>
          <Button
            onClick={() => onRate('OFF_TRACK')}
            loading={reviewMutation.isPending}>
            <div className="flex flex-col items-center gap-4">
              <div className="size-2 rounded-full bg-red"></div>
              <div>Off-track</div>
            </div>
          </Button>
        </div>
      </div>

      <BottomNavigation left={<BackButton />} />
    </>
  );
}
