import { PLAN_WITH_SERVICES } from '@op-platform/backend/founder';
import { useMemo } from 'react';

import { SERVICE_SIZE_VALUES } from '@/lib/constants';
import { Founder } from '@/lib/global-types';
import { convertServicesForUI, sizeToMountCount } from '@/lib/utils';

export const useFounderInfo = (
  founder: Omit<Founder, 'bookings' | 'invoice' | 'project'>,
) => {
  const showingOption = founder.selectedOption
    ? founder.selectedOption
    : founder.optionsPack.options.find(o => o.type === 'REQUESTED')!;

  const hasPlan = showingOption.plan !== null;

  const allServices = useMemo(() => {
    if (showingOption.plan)
      return convertServicesForUI(PLAN_WITH_SERVICES[showingOption.plan]);
    else return convertServicesForUI(showingOption.services);
  }, [showingOption.plan, showingOption.services]);

  const maxDurationService = allServices.sort(
    (a, b) => SERVICE_SIZE_VALUES[b.size] - SERVICE_SIZE_VALUES[a.size],
  )[0];

  const maxDurationMonths = useMemo(() => {
    return sizeToMountCount(maxDurationService!.size) as number;
  }, [maxDurationService]);

  const price = useMemo(() => {
    return allServices.reduce((acc, service) => acc + service.price, 0);
  }, [allServices]);

  return {
    founder,
    hasPlan,
    allServices,
    maxDurationMonths,
    maxDurationService,
    price,
  };
};
