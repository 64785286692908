import * as Sentry from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { TRPCClientError } from '@trpc/client';
import { AxiosError } from 'axios';

import { toast } from '@/lib/utils/toast';

function handleError(error: Error) {
  Sentry.captureException(error);

  if (error instanceof AxiosError) {
    if (error.response?.status === 500) {
      return toast(
        'An error occurred while processing your request. Please contact support.',
      );
    }

    if (!error.response) {
      return toast('An error occurred. Please try again.');
    }

    return toast(error.response.data.message);
  }

  if (error instanceof TRPCClientError) {
    const code = error.data.code;

    if (code === 'UNAUTHORIZED') {
      toast('You are not authorized to perform this action.');
      return window.location.replace('/login');
    }

    return toast(error.message);
  }

  return toast('An error occurred. Please try again.');
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error) {
      handleError(error);
    },
  }),
  mutationCache: new MutationCache({
    onError(error) {
      handleError(error);
    },
  }),
});
