import { isCurrentUpdate } from '@op-platform/backend/project/utils';
import { createFileRoute, Link } from '@tanstack/react-router';
import dayjs from 'dayjs';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { UpdateItem } from '@/components/project/update-item';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';

export const Route = createFileRoute('/dashboard/project/$projectId/updates')({
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    return { project };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { project } = Route.useLoaderData();
  const time = useTimeZoneClock(project.founder.time_zone!);

  const updates = project.updates
    .filter(update => {
      return (
        dayjs(update.date).isBefore(time) ||
        isCurrentUpdate(dayjs(update.date), time)
      );
    })
    .sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));

  return (
    <main className="pb-24">
      <div className="flex flex-col items-center gap-4">
        {updates.length > 0 ? (
          updates.map(update => {
            const isDisabled =
              isCurrentUpdate(dayjs(update.date), time) &&
              update.rating === null;
            return (
              <Link
                disabled={isDisabled}
                key={update.id}
                to="/dashboard/project/$projectId/update/$updateNumber"
                params={{
                  projectId: project.id.toString(),
                  updateNumber: String(update.updateNumber),
                }}>
                <UpdateItem project={project} update={update} />
              </Link>
            );
          })
        ) : (
          <p>No updates yet</p>
        )}
      </div>

      <BottomNavigation left={<BackButton native />} />
    </main>
  );
}
