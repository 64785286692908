import { PLAN_WITH_SERVICES, SERVICES } from '@op-platform/backend/founder';
import { useState } from 'react';

import { FadeContainer } from '@/components/fade-container';
import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from '@/components/feature-groups';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/solid-dialog';
import { Option, Service, ServiceType } from '@/lib/global-types';
import {
  cn,
  convertServicesForUI,
  mountToSize,
  PartialBy,
  sizeToMountCount,
} from '@/lib/utils';

type ValueOf<T> = T[keyof T];
type Entries<T> = [keyof T, ValueOf<T>][];

function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

type Props = {
  open: boolean;
  onClose: () => void;
  option: Option;
  setOption: (payload: Option | null) => void;
};
const OfferDialogInner = ({ option, setOption, open, onClose }: Props) => {
  const { plan: selectedPlan, services: _services } = option;

  const selectedServices = convertServicesForUI(_services);
  const availableServices = convertServicesForUI(
    SERVICES as unknown as Service[],
  );
  const availablePlans = PLAN_WITH_SERVICES;

  const groupedServices = availableServices.reduce(
    (acc, service) => {
      if (!acc[service.type]) {
        acc[service.type] = [];
      }

      acc[service.type].push(service);

      return acc;
    },
    {} as Record<ServiceType, typeof availableServices>,
  );

  const [selectedType, setSelectedType] = useState<'packages' | 'plans'>(
    selectedPlan ? 'plans' : 'packages',
  );

  const handleClose = () => {
    /*     if (!plan && selectedServices.length === 0) {
      setOption(null);
    } */
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <div className="flex max-h-screen w-[100vw] flex-col items-center gap-5 overflow-y-scroll py-20 pb-32 md:gap-10 md:pb-40">
          <h1>Pick your offers</h1>

          <div className="flex items-center gap-4">
            <Button
              className={cn({
                'bg-primary text-white': selectedType === 'packages',
              })}
              onClick={() => setSelectedType('packages')}>
              Packages
            </Button>
            <Button
              className={cn({
                'bg-primary text-white': selectedType === 'plans',
              })}
              onClick={() => setSelectedType('plans')}>
              Plans
            </Button>
          </div>

          {selectedType === 'packages' && (
            <div className="flex flex-col items-center gap-4 md:flex-row">
              {objectEntries(groupedServices).map(([type, services]) => {
                const currentService = selectedServices.find(
                  s => s.type === type,
                );

                const count = !currentService
                  ? 0
                  : sizeToMountCount(currentService.size);

                const dots = [...Array(count)].map((_, i) => (
                  <FeatureDot key={i} animation />
                ));

                return (
                  <Button
                    size={'lg'}
                    key={type}
                    className="group"
                    onClick={() => {
                      const service = services.find(s => s.type === type);
                      const rest = selectedServices.filter(
                        s => s.type !== type,
                      );

                      if (!service) return;

                      const nextMount = mountToSize((count % 4) + 1);

                      const nextSelectedServices = [...rest];

                      if (nextMount) {
                        const nextService = services.find(
                          s => s.size === nextMount && s.type === type,
                        );
                        if (nextService) nextSelectedServices.push(nextService);
                      }

                      setOption({
                        ...option,
                        plan: null,
                        services: nextSelectedServices.map(s => s.full),
                      });
                    }}>
                    <FeatureContainer>
                      <FeatureGroup>
                        <span className="uppercase">{type.charAt(0)}</span>
                      </FeatureGroup>

                      {dots.length > 0 && (
                        <FeatureGroup className="gap-1">{dots}</FeatureGroup>
                      )}
                    </FeatureContainer>
                  </Button>
                );
              })}
            </div>
          )}

          {selectedType === 'plans' && (
            <div className="flex flex-col items-center gap-4 md:flex-row">
              {objectEntries(availablePlans).map(([plan]) => (
                <Button
                  key={plan}
                  size={'lg'}
                  className={cn(
                    {
                      'is-active bg-primary text-white': plan === selectedPlan,
                    },
                    'group-[.is-active] group',
                  )}
                  onClick={() => {
                    const isSamePlan = selectedPlan === plan;
                    setOption({
                      ...option,
                      plan: isSamePlan ? null : plan,
                      services: [],
                    });
                  }}>
                  <FeatureContainer className="group-[.is-active]:border-white group-[.is-active]:after:bg-white">
                    <FeatureGroup className="before:group-[.is-active]:bg-white first:before:group-[.is-active]:bg-white last:after:group-[.is-active]:bg-white">
                      <img
                        src={`/${plan.toLocaleLowerCase()}.png`}
                        alt={plan as string}
                        className="mx-1 mb-1 size-4 rounded-full"
                        width={16}
                        height={16}
                      />
                    </FeatureGroup>
                  </FeatureContainer>
                </Button>
              ))}
            </div>
          )}

          <div className="absolute bottom-4 z-10">
            <FadeContainer className="flex items-center justify-center gap-4">
              <Button onClick={handleClose}>Confirm</Button>
            </FadeContainer>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const OfferDialog = ({
  option,
  ...rest
}: PartialBy<Props, 'option'>) => {
  if (!option) return null;
  return <OfferDialogInner option={option} {...rest} />;
};
