import { isCurrentUpdate } from '@op-platform/backend/project/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { CoundownTimer } from '@/components/countdown-timer';
import { Button } from '@/components/ui/button';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import { useUpdateTimeForDesigner } from '@/hooks/use-update-times';
import { Project, Update } from '@/lib/global-types';
import { cn, getRatingColor } from '@/lib/utils';

type Props = {
  update: Update;
  project: Project;
};
export const UpdateItem = ({ project, update }: Props) => {
  const time = useTimeZoneClock(project.founder.time_zone!);

  const currentUpdate = isCurrentUpdate(dayjs(update.date), time);
  const { startDate, endDate } = useUpdateTimeForDesigner(
    project.founder.time_zone!,
    project.founder.time_zone!,
  );

  const content = useMemo(() => {
    return (
      <span className="flex flex-col items-center gap-2">
        {update.rating !== null && (
          <span
            className={cn(
              'size-2 rounded-full',
              getRatingColor(update.rating),
            )}></span>
        )}
        {update.updateNumber}
      </span>
    );
  }, [update.rating, update.updateNumber]);

  if (currentUpdate) {
    return (
      <CoundownTimer
        size="md"
        startDate={startDate}
        finishDate={endDate}
        timeZone={project.founder.time_zone!}>
        {content}
      </CoundownTimer>
    );
  }

  return <Button asChild>{content}</Button>;
};
