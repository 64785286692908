import { createFileRoute, redirect } from '@tanstack/react-router';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Update } from '@/lib/global-types';
import { guardRoute } from '@/lib/route.utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute(
  '/dashboard/project/$projectId/update/$updateNumber/rate',
)({
  beforeLoad: () => guardRoute(['FOUNDER']),
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const update = project.updates.find(
      update => update.updateNumber === Number(params.updateNumber),
    );

    if (!update) {
      toast("Couldn't find the update");
      throw redirect({
        to: '/dashboard/project/$projectId',
        params: { projectId: project.id.toString() },
        throw: true,
        replace: true,
      });
    }
    return { project, update };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { update, project } = Route.useLoaderData();
  const updateMutation = trpc.project.update.rate.useMutation();
  const navigate = Route.useNavigate();

  const onRate = async (rate: Update['rating']) => {
    if (!rate) return;
    await updateMutation.mutateAsync({
      id: project.id,
      updateId: update.id,
      rating: rate,
    });

    navigate({
      to: '/dashboard/project/$projectId/update/$updateNumber/feedback',
      params: {
        projectId: project.id.toString(),
        updateNumber: String(update.updateNumber),
      },
      replace: true,
    });
  };

  return (
    <>
      <div className="flex flex-col items-center gap-20">
        <h1>How does this update feel?</h1>

        <div className="flex items-center gap-4">
          <Button
            onClick={() => onRate('ON_TRACK')}
            loading={updateMutation.isPending}>
            <div className="flex flex-col items-center gap-4">
              <div className="size-2 rounded-full bg-green"></div>
              <div>On-track</div>
            </div>
          </Button>
          <Button
            onClick={() => onRate('ADJUST')}
            loading={updateMutation.isPending}>
            <div className="flex flex-col items-center gap-4">
              <div className="size-2 rounded-full bg-yellow"></div>
              <div>Adjust</div>
            </div>
          </Button>
          <Button
            onClick={() => onRate('OFF_TRACK')}
            loading={updateMutation.isPending}>
            <div className="flex flex-col items-center gap-4">
              <div className="size-2 rounded-full bg-red"></div>
              <div>Off-track</div>
            </div>
          </Button>
        </div>
      </div>

      <BottomNavigation left={<BackButton />} />
    </>
  );
}
