import {
  Option,
  PLAN_WITH_SERVICES,
  Service,
  SERVICE_DESCRIPTIONS,
  SERVICE_PRICES,
  ServiceSize,
  ServiceType,
} from './constants';

export const SERVICE_SIZE_VALUES = {
  notSure: 0,
  S: 1,
  M: 2,
  L: 3,
};

export const convertServicesForUI = (services: Service[]) => {
  return services.map(service => {
    const [type, size] = service.split('_') as [ServiceType, ServiceSize];
    return {
      type,
      size,
      full: service,
      price: SERVICE_PRICES[service],
      description: SERVICE_DESCRIPTIONS[service],
    };
  });
};

export const getAllServicesFromOption = (option: Option) => {
  if (option.plan) {
    return convertServicesForUI(PLAN_WITH_SERVICES[option.plan]);
  }

  return convertServicesForUI(option.services);
};

export const getProjectAmountFromOption = (option: Option) => {
  const allServices = getAllServicesFromOption(option);
  return allServices.reduce((acc, service) => acc + service.price, 0);
};

export const getProjectDurationMonthFromOption = (option: Option) => {
  const allServices = getAllServicesFromOption(option);
  const maxDurationService = allServices.sort(
    (a, b) => SERVICE_SIZE_VALUES[b.size] - SERVICE_SIZE_VALUES[a.size],
  )[0]!;

  return SERVICE_SIZE_VALUES[maxDurationService.size];
};
