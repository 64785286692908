import '@/lib/setup-pdf';

import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import { ChatDialog } from '@/features/chat/views/chat-dialog';
import { TRPCRouterOutput } from '@/lib/global-types';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute('/dashboard')({
  beforeLoad: ({ location }) => {
    // this will run only dashboard/ route
    if (!location.pathname.match(/\/dashboard\/?$/)) return;

    const cookieStore = window.localStorage;

    const userType = cookieStore.getItem('userType') as
      | TRPCRouterOutput['auth']['loginSSO']['user_type']
      | undefined;

    if (!userType) {
      toast('You are not authorized to access this page.');
      return redirect({ to: '/login' });
    }

    switch (userType) {
      case 'ADMIN':
        return redirect({
          to: '/dashboard/admin',
          search: { filter: undefined },
        });
      case 'FOUNDER':
        return redirect({ to: '/dashboard/founder' });
      case 'DESIGNER':
        return redirect({ to: '/dashboard/designer' });

      default:
        throw new Error(`Unknown user type: ${userType} or not implemented`);
    }

    /*    case "designer":
        return redirect({ to: "/dashboard/designer" });

      case "engineer":
        throw new Error("Not implemented"); */
  },

  component: function Layout() {
    return (
      <>
        <Outlet />
        <ChatDialog />
      </>
    );
  },
});
