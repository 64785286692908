'use client';

import { motion } from 'framer-motion';

export interface FadeScaleContainerProps extends React.PropsWithChildren {
  className?: string;
  duration?: number;
  delay?: number;
}

export const FadeScaleContainer = ({
  children,
  className,
  duration = 0.2,
  delay = 0,
}: FadeScaleContainerProps) => {
  return (
    <motion.div
      className={className}
      animate={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0, scale: 0.9 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration, delay }}
    >
      {children}
    </motion.div>
  );
};

FadeScaleContainer.displayName = 'FadeScaleContainer';
