import { Dayjs } from 'dayjs';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { SYSTEM_TIME } from '@/lib/constants';
import { unFormatTimezone } from '@/lib/utils';

import { StatusCircle } from './status-circle';

const today = SYSTEM_TIME;

type CoundownTimerProps = PropsWithChildren<{
  startDate: Dayjs;
  finishDate: Dayjs;
  timeZone: string;
  size?: 'md' | 'lg';
  className?: string;
}>;
export const CoundownTimer = ({
  startDate,
  finishDate,
  timeZone,
  children,
  size = 'lg',
  className,
}: CoundownTimerProps) => {
  const totalSeconds = finishDate.diff(startDate, 'second');

  const tick = useCallback(() => {
    const secondsPassed = today()
      .tz(unFormatTimezone(timeZone))
      .diff(startDate, 'second');

    const progress = (secondsPassed / totalSeconds) * 100;

    return progress;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone, totalSeconds]);

  const [percentage, setPercentage] = useState(() => tick());

  useEffect(() => {
    const interval = setInterval(() => {
      const progress = tick();

      setPercentage(progress);
      if (progress >= 100) {
        clearInterval(interval);
        return;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [tick]);

  return (
    <StatusCircle size={size} percentage={percentage} className={className}>
      {children}
    </StatusCircle>
  );
};
