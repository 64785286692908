import { createFileRoute, Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { useState } from 'react';
import Markdown from 'react-markdown';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CopyEmailButton } from '@/components/copy-email-button';
import { ReceiptViewer } from '@/components/dialogs/founder/receipt-viewer';
import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from '@/components/feature-groups';
import { FoundersTag } from '@/components/founders-tag';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { useOption } from '@/hooks/use-options';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import { useFounderAIStatusUpdate } from '@/hooks/use-websocket';
import {
  capitalize,
  formatTimezone,
  priceFormatter,
  sizeToMonth,
  sizeToMountCount,
} from '@/lib/utils';

export const Route = createFileRoute(
  '/dashboard/admin/founders/$founderId/info',
)({
  loader: async ({ params }) => {
    const founder = await trpcClientUtils.admin.founder.get.fetch(
      {
        id: parseInt(params.founderId),
      },
      {
        staleTime: 0,
        gcTime: 0,
      },
    );

    return { founder };
  },
  gcTime: 0,
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { founderId } = Route.useParams();
    const [showReceiptViewer, setShowReceiptViewer] = useState(false);

    const [founder, founderQuery] = trpc.admin.founder.get.useSuspenseQuery({
      id: parseInt(founderId),
    });

    const aiSearchMutation = trpc.ai.searchFounder.useMutation();
    useFounderAIStatusUpdate(founder.id, () => {
      founderQuery.refetch();
    });

    const aiStatus = founder.ai_status;

    const selectedOption = useOption(founder.selectedOption);
    const requestedOption = useOption(
      founder.optionsPack.options.find(o => o.type === 'REQUESTED')!,
    );

    const time = useTimeZoneClock(founder.time_zone!);

    const projectStartDate = dayjs(founder.optionsPack.startDate);

    const renderAISection = () => {
      switch (aiStatus) {
        case 'IDLE':
          return (
            <Button
              onClick={async () => {
                await aiSearchMutation.mutateAsync({ founderId: founder.id });
              }}>
              Start AI Search
            </Button>
          );
        case 'PROCESSING':
          return <Spinner />;
        case 'COMPLETED':
          return (
            <Markdown
              components={{
                a: ({ ...props }) => (
                  <a
                    {...props}
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  />
                ),
              }}>
              {founder.ai_description}
            </Markdown>
          );
        case 'FAILED':
          return (
            <Button
              onClick={async () => {
                await aiSearchMutation.mutateAsync({ founderId: founder.id });
              }}>
              AI Search Failed, Retry
            </Button>
          );
      }
    };

    return (
      <div>
        <motion.section
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.99 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="mx-auto flex max-w-[480px] flex-col items-center gap-20 pb-52">
          <div className="flex flex-col items-center">
            <FoundersTag founder={founder} renderSection={['stage']} />
            <CopyEmailButton
              className="mt-10"
              email={founder.user.email}
              text={founder.name!}
            />

            <span className="mt-2">
              {formatTimezone(founder.time_zone || '')}{' '}
              {dayjs(time).format('HH:mm')}
            </span>
          </div>

          {requestedOption.maxDurationService && (
            <div>
              <h1>Founder Requested</h1>
              <p className="mt-2">
                {sizeToMonth(requestedOption.maxDurationService.size)}
              </p>
              <p>{priceFormatter.format(requestedOption.price)}</p>

              <div className="mt-6 flex items-center justify-center gap-2">
                {requestedOption.allServices.map(service => {
                  const dots = Array.from({
                    length: sizeToMountCount(service.size),
                  }).map((_, i) => <FeatureDot key={i} />);

                  return (
                    <FeatureContainer key={service.type}>
                      <FeatureGroup className="uppercase">
                        {service.type.charAt(0)}
                      </FeatureGroup>
                      <FeatureGroup className="gap-2">
                        {dots.length > 0 && dots}
                      </FeatureGroup>
                    </FeatureContainer>
                  );
                })}
              </div>
            </div>
          )}

          {selectedOption && selectedOption.option && (
            <div>
              <h2>
                Founder picked {capitalize(selectedOption.option.type)} option
              </h2>
              <p className="mt-2">
                {sizeToMonth(selectedOption.maxDurationService.size)}
              </p>
              <p>{priceFormatter.format(selectedOption.price)}</p>

              <div className="mt-6 flex items-center justify-center gap-2">
                {selectedOption.allServices.map(service => {
                  const counts = sizeToMountCount(service.size);
                  const dots = [...Array(counts)].map((_, i) => (
                    <FeatureDot key={i} />
                  ));
                  return (
                    <FeatureContainer key={`${service.type}-${service.size}`}>
                      <FeatureGroup>
                        <span className="uppercase">
                          {service.type.charAt(0)}
                        </span>
                      </FeatureGroup>
                      <FeatureGroup className="gap-2">
                        {dots.length > 0 && dots}
                      </FeatureGroup>
                    </FeatureContainer>
                  );
                })}
              </div>
            </div>
          )}

          {selectedOption.option && (
            <div>
              <h3>Project Starting date</h3>
              <p>{projectStartDate.format('DD MMM YYYY HH:mm')}</p>
            </div>
          )}

          <div className="flex flex-col items-center gap-6">
            <h3>Desired outcome</h3>
            <p className="whitespace-pre-line text-center">
              {founder.project_description}
            </p>
          </div>

          {founder.links.length > 0 && (
            <div className="flex flex-col items-center gap-6">
              <h3>Links</h3>
              <div className="flex flex-col gap-2">
                {founder.links.map((link, index) => (
                  <a
                    key={index}
                    className="min-w-4 underline"
                    href={link}
                    target="_blank"
                    rel="noreferrer">
                    {link}
                  </a>
                ))}
              </div>
            </div>
          )}

          <div className="flex gap-2">
            {founder.invoice?.url && (
              <Button asChild>
                <Link
                  to="/dashboard/admin/founders/$founderId/invoice"
                  params={{ founderId: String(founder.id) }}>
                  Invoice
                </Link>
              </Button>
            )}
            {founder.invoice?.receipt_url && (
              <>
                <Button onClick={() => setShowReceiptViewer(true)}>
                  Receipt
                </Button>
                <ReceiptViewer
                  url={founder.invoice.receipt_url}
                  open={showReceiptViewer}
                  onOpenChange={setShowReceiptViewer}
                />
              </>
            )}
          </div>

          {founder.notes && (
            <div className="flex flex-col items-center gap-6">
              <h3>Notes</h3>
              <p className="whitespace-pre-line">{founder.notes}</p>
            </div>
          )}

          <div className="flex flex-col items-center gap-6">
            <h3>AI Description ✨</h3>
            {renderAISection()}
          </div>

          {/* // TODO: add when projects is ready */}
          {/*    {founder.start_date && (
            <div className="flex flex-col items-center">
              <p>Starting day</p>
              <p>{dayjs(founder.start_date).format("DD MMM dddd")}</p>
            </div>
          )}

          {founder.deadline && (
            <div className="flex flex-col items-center">
              <p>Deadline</p>
              <p>{dayjs(founder.deadline).format("DD MMM dddd")}</p>
            </div>
          )} */}
        </motion.section>
        <BottomNavigation
          left={<BackButton native />}
          middle={
            <Button size="default" asChild>
              <Link
                to="/dashboard/admin/founders/$founderId/edit"
                params={{ founderId: String(founder.id) }}>
                Edit
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
