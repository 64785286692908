import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CopyEmailButton } from '@/components/copy-email-button';
import { FoundersTag } from '@/components/founders-tag';
import { Links } from '@/components/links';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';

export const Route = createFileRoute(
  '/dashboard/admin/founders/$founderId/edit',
)({
  loader: async ({ params }) => {
    const founder = await trpcClientUtils.admin.founder.get.ensureData({
      id: parseInt(params.founderId),
    });

    return { founder };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { founder: initialFounder } = Route.useLoaderData();

    const [founder, setFounder] = useState(initialFounder);
    const [newLink, setNewLink] = useState('');
    const navigate = Route.useNavigate();

    const updateMutation = trpc.admin.founder.update.useMutation();

    const onSave = async () => {
      await updateMutation.mutateAsync({
        id: founder.id,
        project_description: founder.project_description ?? '',
        links: founder.links,
        notes: founder.notes ?? '',
      });
      await trpcClientUtils.admin.founder.get.invalidate();

      navigate({ to: '../info', replace: true });
    };

    return (
      <>
        <div className="flex flex-col items-center pb-52">
          <FoundersTag className="mb-20" founder={founder} />

          <CopyEmailButton email={founder.user.email} text={founder.name!} />
          <p className="underline">{founder.company_name}</p>

          <label htmlFor="project-description" className="mb-6 mt-20">
            Desired outcome
          </label>

          <Textarea
            value={founder.project_description || ''}
            onChange={e => {
              setFounder({
                ...founder,
                project_description: e.target.value,
              });
            }}
            placeholder="Description"
            className="w-full"
            id="project-description"
            inputMode="text"
          />

          <label htmlFor="resources" className="mt-10">
            Links
          </label>
          <Links
            currentLink={newLink}
            className="mt-4"
            linkList={founder.links}
            setCurrentLink={setNewLink}
            setLinkList={links =>
              setFounder({
                ...founder,
                links: links as string[],
              })
            }
          />

          <label htmlFor="notes" className="mb-6 mt-10">
            Notes
          </label>
          <Textarea
            value={founder.notes ?? ''}
            onChange={e => {
              setFounder({ ...founder, notes: e.target.value });
            }}
            placeholder="Add notes"
            className="w-full self-center"
            id="notes"
            inputMode="text"
          />
        </div>

        <BottomNavigation
          left={<BackButton native disabled={updateMutation.isPending} />}
          middle={
            <Button loading={updateMutation.isPending} onClick={onSave}>
              Save
            </Button>
          }
        />
      </>
    );
  },
});
