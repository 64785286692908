import dayjs from 'dayjs';
import { Area, AreaChart, CartesianGrid, XAxis } from 'recharts';

import { Button } from '@/components/ui/button';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { SiteReportRoute } from '@/routes/dashboard/admin/site-reports';

const chartConfig = {} satisfies ChartConfig;

export const SocialReports = () => {
  const { socialStats } = SiteReportRoute.useLoaderData();

  const twitterData = socialStats.twitter.map(data => ({
    date: data.created_at,
    followers: data.followers,
    tweets: data.tweets,
  }));

  const instagramData = socialStats.instagram.map(data => ({
    date: data.created_at,
    followers: data.followers,
    posts: data.posts,
  }));

  const youtubeData = socialStats.youtube.map(data => ({
    date: data.created_at,
    subscribers: data.subscribers,
    videos: data.videos,
  }));

  return (
    <div>
      <h3>Social Stats</h3>
      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div>
          <Button asChild size={'sm'}>
            <a
              href="https://x.com/openpurpose"
              target="_blank"
              rel="noreferrer">
              X
            </a>
          </Button>
          <ChartContainer config={chartConfig} className="mt-4">
            <AreaChart
              accessibilityLayer
              data={twitterData}
              margin={{
                left: 12,
                right: 12,
              }}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={value => dayjs(value).format('MMM D HH:mm')}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="dot" hideLabel />}
              />
              <Area
                dataKey="followers"
                type="linear"
                fill="#3B82F6"
                stroke="#3B82F6"
                fillOpacity={0.4}
              />
              <Area
                dataKey="tweets"
                type="linear"
                fill="#10B981"
                stroke="#10B981"
                fillOpacity={0.4}
              />
            </AreaChart>
          </ChartContainer>
        </div>

        <div>
          <Button asChild size={'sm'}>
            <a
              href="https://www.instagram.com/openpurpose/"
              target="_blank"
              rel="noreferrer">
              IG
            </a>
          </Button>
          <ChartContainer config={chartConfig} className="mt-4">
            <AreaChart
              accessibilityLayer
              data={instagramData}
              margin={{
                left: 12,
                right: 12,
              }}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={value => dayjs(value).format('MMM D HH:mm')}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="dot" hideLabel />}
              />
              <Area
                dataKey="followers"
                type="linear"
                fill="#3B82F6"
                stroke="#3B82F6"
                fillOpacity={0.4}
              />
              <Area
                dataKey="posts"
                type="linear"
                fill="#10B981"
                stroke="#10B981"
                fillOpacity={0.4}
              />
            </AreaChart>
          </ChartContainer>
        </div>

        <div>
          <Button asChild size={'sm'}>
            <a
              href="https://www.youtube.com/@openpurpose"
              target="_blank"
              rel="noreferrer">
              YT
            </a>
          </Button>
          <ChartContainer config={chartConfig} className="mt-4">
            <AreaChart
              accessibilityLayer
              data={youtubeData}
              margin={{
                left: 12,
                right: 12,
              }}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={value => dayjs(value).format('MMM D HH:mm')}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="dot" hideLabel />}
              />
              <Area
                dataKey="subscribers"
                type="linear"
                fill="#3B82F6"
                stroke="#3B82F6"
                fillOpacity={0.4}
              />
              <Area
                dataKey="videos"
                type="linear"
                fill="#10B981"
                stroke="#10B981"
                fillOpacity={0.4}
              />
            </AreaChart>
          </ChartContainer>
        </div>
      </div>
    </div>
  );
};
