import { createFileRoute, Link, redirect } from '@tanstack/react-router';

import { usePutFile, useSignFile } from '@/api/services/upload.service';
import { trpcClientUtils } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';

export const Route = createFileRoute('/dashboard/founder/invoice')({
  beforeLoad: async () => {
    const founder = await trpcClientUtils.founder.me.ensureData();

    // If the founder has not yet submitted the invoice, redirect to the dashboard
    if (founder.status !== 'INVOICE_SENT') {
      return redirect({
        to: '/dashboard/founder',
        replace: true,
      });
    }
  },
  component: function Page() {
    const navigate = Route.useNavigate();
    const signFileMutation = useSignFile();
    const putFileQuery = usePutFile();

    const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || !e.target.files[0]) return;
      const file = e.target.files[0];

      const { writeUrl, readUrl } = await signFileMutation.mutateAsync({
        name: file.name,
        type: file.type,
      });

      await putFileQuery.mutateAsync({
        file,
        writeUrl,
      });

      navigate({
        to: '/dashboard/founder/receipt',
        search: {
          receiptUrl: readUrl,
        },
      });
    };

    return (
      <section className="flex flex-col items-center">
        <h1 className="text-center">
          Your invoice is ready.
          <br /> Please make the payment and <br />
          share the receipt to book your project.
        </h1>
        <Link
          to="/dashboard/founder/invoice/detail"
          className="mt-2 text-center underline"
        >
          View invoice
        </Link>

        <Button
          loading={putFileQuery.isPending || signFileMutation.isPending}
          className="relative mt-20 flex size-[224px] items-center justify-center overflow-hidden rounded-full border border-dashed border-black/15 bg-white transition-colors hover:bg-black hover:text-white"
        >
          <span className="text-center">
            Upload <br /> receipt
          </span>

          <input
            type="file"
            className="absolute size-full cursor-pointer opacity-0"
            onChange={uploadFile}
            accept="image/png, image/jpeg, application/pdf"
          />
        </Button>

        <BottomNavigation
          middle={
            <Button size={'sm'} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </section>
    );
  },
});
