'use client';

import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { motion } from 'framer-motion';
import * as React from 'react';

import { cn } from '@/lib/utils';

export const buttonVariants = cva(
  'inline-flex text-wrap relative items-center justify-center whitespace-nowrap ring-offset-white transition-colors focus-visible:outline-none focus-visible:bg-primary focus-visible:text-white disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-white rounded-full text-primary hover:bg-primary hover:text-white border-primary border data-[state=on]:bg-primary data-[state=on]:text-white active:bg-primary active:text-white',
      },
      size: {
        default: 'size-28',
        sm: 'size-14',
        lg: 'size-56',
        xl: 'size-80',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    disabled = disabled || loading;

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          {
            'border-transparent': loading,
            'pointer-events-none opacity-50': disabled,
          },
          'group',
        )}
        ref={ref}
        disabled={disabled}
        {...props}>
        <Slottable>{props.children}</Slottable>
        {loading && (
          <motion.div
            className="absolute inset-0 rounded-full border border-transparent border-t-black hover:border-t-white"
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
          />
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';
