import Axios, { AxiosRequestConfig } from 'axios';

import { BACKEND_URL } from '@/lib/constants';

const getHeaders = () => {
  const cookieStore = window.localStorage;
  const accessToken = cookieStore.getItem('accessToken') || '';

  const commonHeaders = {
    /** Other headers */
  };

  return {
    Authorization: `Bearer ${accessToken}`,
    ...commonHeaders,
  };
};

export const appClient = Axios.create({
  baseURL: BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

export const get = async <T>(url: string, options?: AxiosRequestConfig) => {
  const response = await appClient.get<T>(url, {
    headers: getHeaders(),
    ...options,
  });

  return response.data;
};

export const post = async <T>(
  url: string,
  data?: unknown,
  options?: AxiosRequestConfig,
) => {
  const response = await appClient.post<T>(url, data, {
    headers: getHeaders(),
    ...options,
  });

  return response.data;
};

export const put = async <T>(
  url: string,
  data?: unknown,
  options?: AxiosRequestConfig,
) => {
  const response = await appClient.put<T>(url, data, {
    headers: getHeaders(),
    ...options,
  });

  return response.data;
};

export const del = async <T>(url: string, options?: AxiosRequestConfig) => {
  const response = await appClient.delete<T>(url, {
    headers: getHeaders(),
    ...options,
  });

  return response.data;
};

export const patch = async <T>(
  url: string,
  data?: unknown,
  options?: AxiosRequestConfig,
) => {
  const response = await appClient.patch<T>(url, data, {
    headers: getHeaders(),
    ...options,
  });

  return response.data;
};
