import { useMutation } from '@tanstack/react-query';
import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import { Document, Page as PDFPage } from 'react-pdf';

import { trpc } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';
import { useIsMobile } from '@/hooks/use-is-mobile';

export const Route = createFileRoute('/dashboard/founder/receipt')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      receiptUrl: (search.receiptUrl as string) || '',
    };
  },
  beforeLoad: async ({ search }) => {
    if (!search.receiptUrl) {
      return redirect({ to: '/dashboard/founder' });
    }
  },
  component: function Page() {
    const { receiptUrl } = Route.useSearch();
    const navigate = Route.useNavigate();
    const { isMobile } = useIsMobile();

    const addReceiptMutation = trpc.founder.saveReceipt.useMutation();

    const uploadReceiptMutation = useMutation({
      mutationFn: async () => {
        await addReceiptMutation.mutateAsync({ receipt_url: receiptUrl });
        navigate({ to: '/dashboard/founder' });
      },
    });

    const pdfWidth = isMobile ? window?.innerWidth || 600 : 800;
    const renderContent = () => {
      const extention = receiptUrl.split('.').pop();

      switch (extention) {
        case 'pdf':
          return (
            <Document file={receiptUrl} renderMode="canvas">
              <PDFPage width={pdfWidth} pageNumber={1} />
            </Document>
          );
        // we assume that user will upload image or pdf
        default:
          return (
            <img src={receiptUrl} alt="receipt" width={600} height={800} />
          );
      }
    };

    return (
      <section className="flex flex-col items-center gap-20 pb-52 text-center">
        <p>
          Receipt uploaded. <br /> Please confirm.
        </p>
        <div className="relative border border-black/10">{renderContent()}</div>

        <BottomNavigation
          className="z-20"
          left={
            <Button size={'sm'} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button
              loading={uploadReceiptMutation.isPending}
              onClick={() => uploadReceiptMutation.mutate()}>
              Confirm
            </Button>
          }
        />
      </section>
    );
  },
});
