import { createFileRoute } from '@tanstack/react-router';

import { NewsletterSignup } from '@/components/newsletter-signup';
import { OPLogo } from '@/components/op-logo';
import { OPLogoLarge } from '@/components/op-logo-large';
import { SlideContainer } from '@/components/slide-container';

export const Route = createFileRoute('/_misc/_newsletter/updates')({
  component: function Page() {
    return (
      <main className="flex size-full min-h-dvh flex-col items-center justify-center">
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
          <a href="https://openpurpose.com" rel="noreferrer">
            <OPLogoLarge />
          </a>
        </header>

        <SlideContainer className="flex flex-col items-center justify-center gap-10 text-center">
          <h2>
            Our newsletter is an open journal. <br />
            Keep up with our thinking. <br />
            Get closer to our work.
          </h2>

          <h2 className="text-center">
            <NewsletterSignup />
          </h2>
        </SlideContainer>

        <section className="fixed bottom-6 left-0 right-0 z-20 flex items-center justify-center md:bottom-10">
          <a href="https://x.com/openpurpose" target="_blank" rel="noreferrer">
            <OPLogo />
          </a>
        </section>
      </main>
    );
  },
});
