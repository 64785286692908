import { useRouterState } from '@tanstack/react-router';
import umami from '@umami/node';
import { useEffect } from 'react';

import { IS_DEV } from '@/lib/constants';

const UMAMI_SITE_ID = IS_DEV
  ? '9995fa0e-e768-4090-bfa2-acf70df2f56b'
  : '41eb995d-639d-4fcd-a94c-cd6c552d2ad6';

export function initUmami() {
  umami.init({
    websiteId: UMAMI_SITE_ID,
    hostUrl: 'https://data.openpurpose.com',
  });
}

export function useUmamiTrackPages() {
  const pathname = useRouterState({
    select: state => state.location.pathname,
  });

  useEffect(() => {
    if (!pathname) return;
    umami.track({ url: pathname, referrer: document.referrer });
  }, [pathname]);
}

export function useUmamiPageEvent(name: string) {
  useEffect(() => {
    umami.track(name);
  }, [name]);
}
