import { observable } from '@legendapp/state';

import { TRPCRouterInput } from '@/lib/global-types';

export type DesignerOnboardInput = TRPCRouterInput['designer']['onboard'];

export type CreativeOnboardingPayload = {
  name: DesignerOnboardInput['name'];
  time_zone: DesignerOnboardInput['time_zone'];
  level: DesignerOnboardInput['level'] | null;
};

export const creativeOnboardingState$ = observable<CreativeOnboardingPayload>({
  name: '',
  time_zone: '',
  level: null,
});

export const isCreativeOnboardingCompleted$ = observable(() => {
  return (
    creativeOnboardingState$.name.get().length > 0 ||
    creativeOnboardingState$.time_zone.get().length > 0 ||
    creativeOnboardingState$.level.get() !== null
  );
});
