import { createFileRoute, redirect } from '@tanstack/react-router';
import umami from '@umami/node';
import { motion } from 'framer-motion';
import { useState } from 'react';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { TRPCRouterOutput } from '@/lib/global-types';
import { useUmamiPageEvent } from '@/lib/umami';
import { priceFormatter, sizeToMonth } from '@/lib/utils';
import {
  FounderNewState,
  founderNewState$,
  isFounderNewCompleted$,
} from '@/store/founder.state';

const DESCRIPTIONS: Record<
  TRPCRouterOutput['misc']['plans'][number]['name'],
  React.ReactNode
> = {
  ESSENTIAL: (
    <p>
      Essential includes <br />
      a new identity design + <br />
      Website of 1-2 pages + <br />
      Single purpose product.
    </p>
  ),
  EXTENDED: (
    <p>
      Extended includes a new <br /> identity with illustrations + <br />{' '}
      Website of 3-5 pages + <br />
      Multi purpose product.
    </p>
  ),
  BEYOND: (
    <p>
      Beyond includes a new <br /> identity with advanced 3D + <br /> Website of
      6+ pages + Multi <br /> platform product.
    </p>
  ),
};

export const Route = createFileRoute('/new/founder/plans')({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: '/new/founder' });
    }
  },
  loader: () => {
    return trpcClientUtils.misc.plans.ensureData();
  },

  staleTime: Infinity,
  component: function Page() {
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const { isMobile } = useIsMobile();
    const plan = founderNewState$.requested_option.plan.get();

    const plans = Route.useLoaderData();
    const navigate = Route.useNavigate();

    useUmamiPageEvent('Founder - Plans Selector');

    return (
      <div>
        <section className="text-center">
          <h1 className="mt-10">
            Our Plans cover end-to-end designs. <br /> From Brand to Website and
            Product in 1-3 months led by <br /> our team in parallel. What fits
            your budget best?
          </h1>

          <button
            className="mt-2 underline"
            onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
            {isDetailsOpen ? 'Hide' : 'View'} details
          </button>

          <ToggleGroup
            type="single"
            className="mt-10 w-screen md:mt-20 md:w-full"
            defaultValue={plan as string}
            data-cy="plans-toggle"
            onValueChange={value => {
              if (value) {
                founderNewState$.requested_option.plan.set(
                  value as FounderNewState['requested_option']['plan'],
                );
                founderNewState$.requested_option.services.set([]);

                umami.track('Founder Answer - Plan Selector', {
                  Plan: value,
                });
                navigate({ to: '/new/founder/time' });
              }
            }}>
            <Carousel
              opts={{
                containScroll: isMobile ? false : 'keepSnaps',
                active: isMobile,
              }}
              className="w-full">
              <CarouselContent>
                {plans.map(plan => {
                  return (
                    <CarouselItem
                      data-cy={`plan-${plan}`}
                      key={plan.name}
                      className="flex basis-[80%] items-center justify-center md:basis-1/3">
                      <ToggleGroupItem
                        key={plan.name}
                        className="group flex size-80 flex-col items-center gap-6"
                        value={plan.name}>
                        <motion.span
                          layout
                          layoutDependency={isDetailsOpen}
                          variants={{
                            normal: { scale: 1 },
                            small: {
                              y: -30,
                              scale: 0.3,
                            },
                          }}
                          animate={`${isDetailsOpen ? 'small' : 'normal'}`}>
                          <img
                            src={`/${plan.name.toLocaleLowerCase()}.png`}
                            width={120}
                            height={120}
                            alt={plan.name}
                          />
                        </motion.span>

                        {isDetailsOpen && (
                          <motion.span
                            layoutDependency={isDetailsOpen}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="-mt-16 flex">
                            {DESCRIPTIONS[plan.name]}
                          </motion.span>
                        )}

                        <motion.div
                          layout
                          layoutDependency={isDetailsOpen}
                          className="flex flex-col items-center">
                          <span>{sizeToMonth(plan.services[0]!.size)}</span>
                          <span>{priceFormatter.format(plan.price)}</span>
                        </motion.div>
                      </ToggleGroupItem>
                    </CarouselItem>
                  );
                })}
              </CarouselContent>
            </Carousel>
          </ToggleGroup>
        </section>

        <BottomNavigation left={<BackButton native />} />
      </div>
    );
  },
});
