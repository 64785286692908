import { createFileRoute, redirect } from '@tanstack/react-router';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { LinkButton } from '@/components/link-button';
import { Button } from '@/components/ui/button';
import { cn, getRatingColor, getUserTypeFromLocalStorage } from '@/lib/utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute(
  '/dashboard/project/$projectId/review/$reviewNumber/_$reviewNumber/',
)({
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const review = project.reviews.find(
      review => review.reviewNumber === Number(params.reviewNumber),
    );

    if (!review) {
      toast("Couldn't find the Review");
      throw redirect({
        to: '/dashboard/project/$projectId',
        params: { projectId: project.id.toString() },
        throw: true,
        replace: true,
      });
    }

    return { project, review };
  },
  component: RouteComponent,
});
function RouteComponent() {
  const { review, project } = Route.useLoaderData();
  const { isAdmin } = getUserTypeFromLocalStorage();

  const needToRate = review.rating === null && isAdmin;

  return (
    <>
      <div className="flex flex-col items-center gap-20 pb-40">
        <div className="flex flex-col items-center gap-10">
          <LinkButton to="..">
            <Button
              className={cn({
                'border-gray-200': review.rating === null,
              })}>
              <span className="flex flex-col items-center gap-2">
                {review.rating !== null && (
                  <span
                    className={cn(
                      'size-2 rounded-full',
                      getRatingColor(review.rating),
                    )}></span>
                )}
                <span>{review.reviewNumber}</span>
              </span>
            </Button>
          </LinkButton>
          <p className="max-w-[450px] text-center">{review.description}</p>
        </div>

        {review.feedback && (
          <div className="flex flex-col items-center gap-6">
            <p>Admin Feedback</p>
            <p className="max-w-[450px] text-center">{review.feedback}</p>
          </div>
        )}

        <div className="flex flex-col items-center gap-10">
          {review.assets?.map((imageSource, index) => (
            <div
              key={imageSource}
              className={cn(
                'group relative flex aspect-video w-[90vw] max-w-[960px] items-center justify-center overflow-hidden rounded-2xl ring-1 ring-gray-200',
              )}>
              <img
                height={540}
                width={960}
                src={imageSource}
                alt={`preview of image ${index}`}
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center gap-6">
          <p>Next steps</p>
          <p className="max-w-[450px] text-center">
            {review.nextStepDescription}
          </p>
        </div>
      </div>

      <BottomNavigation
        left={<BackButton native />}
        middle={
          needToRate && (
            <LinkButton
              to="/dashboard/project/$projectId/review/$reviewNumber/rate"
              params={{
                projectId: project.id.toString(),
                updateNumber: String(review.reviewNumber),
              }}>
              Rate
            </LinkButton>
          )
        }
      />
    </>
  );
}
