import { createFileRoute, Link } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';

export const Route = createFileRoute(
  '/dashboard/admin/designers/$designerId/rate',
)({
  component: function Page() {
    const { designerId } = Route.useParams();
    const [isRejectConfirmed, setIsRejectConfirmed] = useState(false);
    const navigate = Route.useNavigate();

    const approveDesigner = trpc.admin.designer.approve.useMutation();
    const rejectDesigner = trpc.admin.designer.reject.useMutation();

    const onApprove = async () => {
      await approveDesigner.mutateAsync({ id: parseInt(designerId) });

      navigate({
        to: '/dashboard/admin/designers/$designerId',
        params: { designerId },
      });
    };

    const onReject = async () => {
      await rejectDesigner.mutateAsync({ id: parseInt(designerId) });
      navigate({
        to: '/dashboard/admin',
        search: { filter: undefined },
      });
    };

    return (
      <div>
        <div className="flex flex-col items-center gap-20">
          <h1>{"How's the work?"}</h1>

          <div className="flex gap-4">
            <Button onClick={onApprove}>Yes</Button>
            <Button
              loading={rejectDesigner.isPending}
              onClick={() => {
                if (!isRejectConfirmed) {
                  setIsRejectConfirmed(true);
                  return;
                }
                onReject();
              }}>
              {isRejectConfirmed ? 'Sure?' : 'No'}
            </Button>
          </div>
        </div>

        <BottomNavigation
          left={
            <Button size="sm" disabled={rejectDesigner.isPending} asChild>
              <Link
                to={`/dashboard/admin/designers/$designerId`}
                params={{ designerId: designerId }}>
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
