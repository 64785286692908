import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import { motion } from 'framer-motion';

import { FadeSection } from '@/components/fade-section';
import { NewsletterSignup } from '@/components/newsletter-signup';
import { OPLogo } from '@/components/op-logo';
import { OPLogoLarge } from '@/components/op-logo-large';
import { Button } from '@/components/ui/button';
import { getUserTypeFromLocalStorage } from '@/lib/utils';

export const Route = createFileRoute('/')({
  beforeLoad: () => {
    const { type } = getUserTypeFromLocalStorage();

    switch (type) {
      case 'FOUNDER':
        return redirect({ to: '/dashboard/founder', replace: true });
      case 'DESIGNER':
        return redirect({ to: '/dashboard/designer', replace: true });
      case 'ADMIN':
        return redirect({
          to: '/dashboard/admin',
          replace: true,
          search: { filter: undefined },
        });
    }
  },
  component: HomeComponent,
});

export function HomeComponent() {
  return (
    <div className="grid h-svh grid-rows-[min-content,auto,min-content] place-items-center">
      <header className="flex w-full justify-center py-10 text-white mix-blend-difference">
        <Link to="/">
          <OPLogoLarge />
        </Link>
      </header>

      <motion.section>
        <FadeSection>
          <div className="gap-4 text-center">
            Some fear change. <br />
            Some resist it. Others try to adapt.
            <br />
            OpenPurpose
            <span className="inline-block translate-y-[-7px] text-[9px] font-light">
              ®
            </span>{' '}
            exists to be the change.
            <br />
            <br />
            It’s time to take the next step
            <br />
            towards creating the world’s first
            <br />
            Creative Venture Platform.
            <br />
            <br />
            <div className="inline-flex items-start justify-start">
              <span>OP</span>
              <span className="inline-block text-[9px] font-light leading-4">
                ®
              </span>
            </div>{' '}
            Academy is on the way.
            <br /> Sign up for updates.
          </div>

          <div className="mt-10">
            <NewsletterSignup />
          </div>

          <div className="mt-10 flex flex-row items-center justify-center gap-2 md:mt-10">
            <Button asChild size={'sm'}>
              <a
                href="https://x.com/openpurpose"
                target="_blank"
                rel="noreferrer">
                X
              </a>
            </Button>
            <Button asChild size={'sm'}>
              <a
                href="https://www.instagram.com/openpurpose/"
                target="_blank"
                rel="noreferrer">
                IG
              </a>
            </Button>
            <Button asChild size={'sm'}>
              <a
                href="https://www.youtube.com/@openpurpose"
                target="_blank"
                rel="noreferrer">
                YT
              </a>
            </Button>
          </div>
        </FadeSection>
      </motion.section>

      <footer className="flex w-full flex-col gap-10 py-10">
        <Link to="/" className="mx-auto inline-block">
          <OPLogo />
        </Link>
      </footer>
    </div>
  );
}
