import { generateChannelId } from '@op-platform/backend/chat/utils';
import {
  getCurrentReview,
  getCurrentUpdate,
  hasLastSentReviewWithin6Hours,
  hasLastSentUpdateWithin24Hours,
} from '@op-platform/backend/project/utils';
import { createFileRoute, Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CoundownTimer } from '@/components/countdown-timer';
import { FoundersTag } from '@/components/founders-tag';
import { SlideContainer } from '@/components/slide-container';
import { Button } from '@/components/ui/button';
import { chatState$ } from '@/features/chat/chat.state';
import { useProject } from '@/hooks/use-project';
import { useReviewTimesForDesigner } from '@/hooks/use-review-times';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import { useUpdateTimeForDesigner } from '@/hooks/use-update-times';
import { IS_DEV } from '@/lib/constants';
import {
  formatTimezone,
  getRatingColor,
  getUserTypeFromLocalStorage,
} from '@/lib/utils';

export const Route = createFileRoute(
  '/dashboard/project/$projectId/_$projectId/',
)({
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    return { project };
  },
  gcTime: 0,
  component: RouteComponent,
});

function RouteComponent() {
  const { projectId } = Route.useParams();
  const { project } = Route.useLoaderData();
  const founder = project.founder;

  const time = useTimeZoneClock(founder.time_zone!);

  const updateTimes = useUpdateTimeForDesigner(
    founder.time_zone!,
    founder.time_zone!,
  );

  const reviewTimes = useReviewTimesForDesigner(
    founder.time_zone!,
    founder.time_zone!,
  );

  const projectDetails = useProject(project);

  const sentUpdates = project.updates.filter(
    update => update.status !== 'CREATED',
  );

  const sentReviews = project.reviews.filter(
    review => review.status !== 'CREATED',
  );

  const seenByUpdateMutation = trpc.project.update.seenBy.useMutation();

  const { isFounder, isAdmin, isDesigner } = getUserTypeFromLocalStorage();

  const renderUpdateActionButton = () => {
    if (!projectDetails.isStarted)
      return (
        <Button size={'lg'} disabled>
          <span>Project not started yet</span>
        </Button>
      );

    const currentUpdate = getCurrentUpdate(project.updates, time);
    if (!currentUpdate) return null;

    const currentUpdateHasRated = currentUpdate.rating !== null;
    const currentUpdateHasSent = currentUpdate.status === 'SENT';

    const lastSentUpdateWithin24Hours = hasLastSentUpdateWithin24Hours(
      project.updates,
      time,
    );

    if (isAdmin || isDesigner) {
      if (
        updateTimes.isAllowedSending &&
        !currentUpdateHasSent &&
        !currentUpdateHasRated
      ) {
        return (
          <Button size="lg" asChild>
            <Link
              to="/dashboard/project/$projectId/update/$updateNumber/create"
              params={{
                projectId: projectId,
                updateNumber: String(currentUpdate.updateNumber),
              }}>
              Send Update
            </Link>
          </Button>
        );
      } else {
        if (lastSentUpdateWithin24Hours) {
          if (lastSentUpdateWithin24Hours.update.status === 'RATED') {
            const bgColor = getRatingColor(
              lastSentUpdateWithin24Hours.update.rating,
            );
            return (
              <Button
                size={'lg'}
                asChild
                className={bgColor}
                onClick={() =>
                  seenByUpdateMutation.mutate({
                    id: project.id,
                    updateId: lastSentUpdateWithin24Hours.update.id,
                    by: 'DESIGNER',
                  })
                }>
                <Link
                  to="/dashboard/project/$projectId/update/$updateNumber"
                  params={{
                    projectId: projectId,
                    updateNumber: String(
                      lastSentUpdateWithin24Hours.update.updateNumber,
                    ),
                  }}>
                  See Review
                </Link>
              </Button>
            );
          }
          if (lastSentUpdateWithin24Hours.update.status === 'SENT') {
            return (
              <Link
                to="/dashboard/project/$projectId/update/$updateNumber/create"
                params={{
                  projectId: projectId,
                  updateNumber: String(currentUpdate.updateNumber),
                }}>
                <CoundownTimer
                  startDate={lastSentUpdateWithin24Hours.startDate}
                  finishDate={lastSentUpdateWithin24Hours.endDate}
                  timeZone={founder.time_zone!}>
                  Waiting to rate
                </CoundownTimer>
              </Link>
            );
          }
        } else {
          return (
            <CoundownTimer
              startDate={updateTimes.startDate}
              finishDate={updateTimes.endDate}
              timeZone={founder.time_zone!}>
              {updateTimes.nextUpdateText}
            </CoundownTimer>
          );
        }
      }
    } else if (isFounder) {
      if (
        lastSentUpdateWithin24Hours &&
        lastSentUpdateWithin24Hours.update.status !== 'RATED'
      ) {
        return (
          <Link
            to="/dashboard/project/$projectId/update/$updateNumber"
            params={{
              projectId: projectId,
              updateNumber: String(
                lastSentUpdateWithin24Hours.update.updateNumber,
              ),
            }}
            onClick={() =>
              seenByUpdateMutation.mutate({
                id: project.id,
                updateId: lastSentUpdateWithin24Hours.update.id,
                by: 'FOUNDER',
              })
            }>
            <CoundownTimer
              startDate={lastSentUpdateWithin24Hours.startDate}
              finishDate={lastSentUpdateWithin24Hours.endDate}
              timeZone={founder.time_zone!}>
              View Update
            </CoundownTimer>
          </Link>
        );
      }

      if (currentUpdateHasRated) {
        const bgColor = getRatingColor(currentUpdate.rating!);

        return (
          <Button
            size="lg"
            asChild
            className={bgColor}
            onClick={() =>
              seenByUpdateMutation.mutate({
                id: project.id,
                updateId: currentUpdate.id,
                by: 'FOUNDER',
              })
            }>
            <Link
              to="/dashboard/project/$projectId/update/$updateNumber"
              params={{
                projectId: projectId,
                updateNumber: String(currentUpdate.updateNumber),
              }}>
              View Update
            </Link>
          </Button>
        );
      }
      if (updateTimes.isAllowedSending && !currentUpdateHasSent) {
        return (
          <Button disabled size="lg">
            Waiting for designer to send update
          </Button>
        );
      } else {
        return (
          <CoundownTimer
            startDate={updateTimes.startDate}
            finishDate={updateTimes.endDate}
            timeZone={founder.time_zone!}>
            {updateTimes.nextUpdateText}
          </CoundownTimer>
        );
      }
    }
  };

  const renderReviewActionButton = () => {
    // do not show review button for founder
    if (isFounder) return null;

    if (!projectDetails.isStarted)
      return (
        <Button size={'lg'} disabled>
          <span>Project not started yet</span>
        </Button>
      );

    const currentReview = getCurrentReview(project.reviews, time);

    if (!currentReview) return null;

    const currentReviewHasRated = currentReview.rating !== null;
    const currentReviewHasSent = currentReview.status === 'SENT';

    const lastSentReviewWithin6Hours = hasLastSentReviewWithin6Hours(
      project.reviews,
      time,
    );

    // admin should rate the review, designer should sent the review
    if (isDesigner) {
      if (
        reviewTimes.isAllowedSending &&
        !currentReviewHasSent &&
        !currentReviewHasRated
      ) {
        return (
          <Button size="lg" asChild>
            <Link
              to="/dashboard/project/$projectId/review/$reviewNumber/create"
              params={{
                projectId: projectId,
                reviewNumber: String(currentReview.reviewNumber),
              }}>
              Send Review
            </Link>
          </Button>
        );
      } else {
        if (lastSentReviewWithin6Hours) {
          if (lastSentReviewWithin6Hours.review.status === 'RATED') {
            const bgColor = getRatingColor(
              lastSentReviewWithin6Hours.review.rating,
            );
            return (
              <Button size={'lg'} asChild className={bgColor}>
                <Link
                  to="/dashboard/project/$projectId/review/$reviewNumber"
                  params={{
                    projectId: projectId,
                    reviewNumber: String(currentReview.reviewNumber),
                  }}>
                  See Review
                </Link>
              </Button>
            );
          }
          if (lastSentReviewWithin6Hours.review.status === 'SENT') {
            return (
              <Link
                to="/dashboard/project/$projectId/review/$reviewNumber"
                params={{
                  projectId: projectId,
                  reviewNumber: String(currentReview.reviewNumber),
                }}>
                <CoundownTimer
                  startDate={lastSentReviewWithin6Hours.startDate}
                  finishDate={lastSentReviewWithin6Hours.endDate}
                  timeZone={founder.time_zone!}>
                  Waiting to rate
                </CoundownTimer>
              </Link>
            );
          }
        } else {
          return (
            <CoundownTimer
              startDate={reviewTimes.startDate}
              finishDate={reviewTimes.endDate}
              timeZone={founder.time_zone!}>
              {reviewTimes.nextReviewText}
            </CoundownTimer>
          );
        }
      }
    } else if (isAdmin) {
      if (
        lastSentReviewWithin6Hours &&
        lastSentReviewWithin6Hours.review.status !== 'RATED'
      ) {
        return (
          <Link
            to="/dashboard/project/$projectId/review/$reviewNumber"
            params={{
              projectId: projectId,
              reviewNumber: String(
                lastSentReviewWithin6Hours.review.reviewNumber,
              ),
            }}>
            <CoundownTimer
              startDate={lastSentReviewWithin6Hours.startDate}
              finishDate={lastSentReviewWithin6Hours.endDate}
              timeZone={founder.time_zone!}>
              View Review
            </CoundownTimer>
          </Link>
        );
      }

      if (currentReviewHasRated) {
        const bgColor = getRatingColor(currentReview.rating!);

        return (
          <Button size="lg" asChild className={bgColor}>
            <Link
              to="/dashboard/project/$projectId/review/$reviewNumber"
              params={{
                projectId: projectId,
                reviewNumber: String(currentReview.reviewNumber),
              }}>
              View Review
            </Link>
          </Button>
        );
      }
      if (reviewTimes.isAllowedSending && !currentReviewHasSent) {
        return (
          <Button disabled size="lg">
            Waiting for designer to send review
          </Button>
        );
      } else {
        return (
          <CoundownTimer
            startDate={reviewTimes.startDate}
            finishDate={reviewTimes.endDate}
            timeZone={founder.time_zone!}>
            {reviewTimes.nextReviewText}
          </CoundownTimer>
        );
      }
    }
  };

  const renderUpdateContent = () => {
    return (
      <div className="flex flex-col items-center gap-10">
        {renderUpdateActionButton()}
        {sentUpdates.length > 0 && (
          <Button asChild>
            <Link
              to="/dashboard/project/$projectId/updates"
              params={{ projectId: projectId }}>
              Updates
            </Link>
          </Button>
        )}
      </div>
    );
  };

  const renderReviewContent = () => {
    if (isFounder) return null;
    return (
      <div className="flex flex-col items-center gap-10">
        {renderReviewActionButton()}
        {sentReviews.length > 0 && (
          <Button asChild>
            <Link
              to="/dashboard/project/$projectId/reviews"
              params={{ projectId: projectId }}>
              Reviews
            </Link>
          </Button>
        )}
      </div>
    );
  };
  return (
    <main>
      <motion.div
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 0.99 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        className="mx-auto flex max-w-[480px] flex-col items-center gap-10 text-center">
        {/*  <ProjectTag project={project} /> */}
        <FoundersTag
          founder={founder}
          renderSection={['stage', 'plan', 'services', 'duration']}
        />

        <div className="flex flex-col items-center">
          <Link
            to="/dashboard/project/$projectId/info"
            params={{ projectId: projectId }}
            className="underline">
            {founder.company_name}
          </Link>
          <span className="mt-2">
            {formatTimezone(founder.time_zone || '')}{' '}
            {dayjs(time).format('HH:mm')}
          </span>
        </div>
        <SlideContainer animationKey={founder.status} className="mt-10">
          <div className="flex flex-col items-center gap-10">
            <div className="flex flex-row gap-10">
              {renderUpdateContent()}
              {renderReviewContent()}
            </div>
          </div>
        </SlideContainer>
      </motion.div>
      {IS_DEV && (
        <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
          <span>
            founder email: {founder.user.email}
            <button
              className="ml-1 border border-dashed border-gray-600"
              onClick={() => navigator.clipboard.writeText(founder.user.email)}>
              copy
            </button>
          </span>
          <span>founder id: {founder.id}</span>
          <span>founder timezone: {founder.time_zone}</span>

          <hr className="h-0.5 w-full bg-gray-600" />

          {project.designers.length > 0 &&
            project.designers.map(designer => (
              <div
                className="my-4 flex flex-col text-left"
                key={designer.user.id}>
                <span>
                  designer email: {designer.user.email}
                  <button
                    className="ml-1 border border-dashed border-gray-600"
                    onClick={() =>
                      navigator.clipboard.writeText(designer.user.email)
                    }>
                    copy
                  </button>
                </span>
                <span>designer id: {designer.id}</span>
                <span>designer timezone: {designer.time_zone}</span>
              </div>
            ))}

          <hr className="h-0.5 w-full bg-gray-600" />
          <span>
            Date & Time (DD//MM/YYYY):{' '}
            {time.format('DD/MM/YYYY HH:mm (Z) - dddd')}
          </span>
        </code>
      )}

      <BottomNavigation
        left={(isAdmin || isDesigner) && <BackButton native />}
        middle={
          (isAdmin || isFounder) && (
            <Button
              onClick={() => {
                const channelChatId = generateChannelId(project);
                chatState$.channelId.set(channelChatId);
                chatState$.dialogOpen.set(true);
              }}>
              Chat
            </Button>
          )
        }
      />
    </main>
  );
}
