import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

interface Props extends PropsWithChildren {
  className?: string;
  percentage: number;
  size?: 'md' | 'lg';
}

export const StatusCircle = ({
  children,
  className,
  percentage,
  size = 'lg',
}: Props) => {
  return (
    <div
      suppressHydrationWarning
      style={{
        background: `conic-gradient(black ${percentage}%, #00000014 0)`,
      }}
      className={cn(
        'flex items-center justify-center rounded-full',
        {
          'size-[224px]': size === 'lg',
          'size-[112px]': size === 'md',
        },
        className,
      )}>
      <div
        className={cn(
          'flex items-center justify-center rounded-full bg-white px-4 text-center',
          {
            'size-[222px]': size === 'lg',
            'size-[110px]': size === 'md',
          },
        )}>
        {children}
      </div>
    </div>
  );
};
StatusCircle.displayName = 'StatusCircle';
