import { createFileRoute, redirect } from '@tanstack/react-router';

import { trpc } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import {
  founderOnboardingState$,
  isFounderOnboardingCompleted$,
  MonthlyEmailPreference,
} from '@/store/founder.state';

export const Route = createFileRoute('/onboarding/founder/mail-preference')({
  beforeLoad: () => {
    if (!isFounderOnboardingCompleted$.peek()) {
      return redirect({ to: '/onboarding/founder' });
    }
  },
  component: function Page() {
    const is_newsletter_allowed =
      founderOnboardingState$.is_newsletter_allowed.get();
    const navigate = Route.useNavigate();

    const onboardFounderMutation = trpc.founder.onboard.useMutation();

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      const founder = founderOnboardingState$.get();

      await onboardFounderMutation.mutateAsync({
        name: founder.name,
        time_zone: founder.time_zone!,
        company_name: founder.company_name,
        project_description: founder.project_outcome_description,
        links: founder.links,
        is_newsletter_allowed: !!is_newsletter_allowed,
      });

      navigate({
        to: '/dashboard/founder',
        replace: true,
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center"
      >
        <h1 className="mt-10 max-w-[320px] text-balance md:mt-20 md:max-w-[400px]">
          {'Do you want to get our monthly emails with new'}{' '}
          {'collaborations and product updates?'}
        </h1>

        <div className="mt-20 flex justify-center gap-2">
          <ToggleGroup
            type="single"
            defaultValue={
              is_newsletter_allowed === undefined
                ? undefined
                : is_newsletter_allowed
                  ? 'yes'
                  : 'no'
            }
            onValueChange={value => {
              if (value)
                founderOnboardingState$.is_newsletter_allowed.set(
                  value as MonthlyEmailPreference,
                );
            }}
          >
            <ToggleGroupItem value="yes">Yes</ToggleGroupItem>
            <ToggleGroupItem value="no">No</ToggleGroupItem>
          </ToggleGroup>
        </div>
        <BottomNavigation
          left={<BackButton native />}
          middle={is_newsletter_allowed && <Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
