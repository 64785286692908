import { createFileRoute, Link } from '@tanstack/react-router';

import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { ServiceType } from '@/lib/global-types';
import { designerCreateState$ } from '@/store/designer.state';

export const Route = createFileRoute('/new/designer/')({
  component: function Page() {
    const services = designerCreateState$.services.get();

    return (
      <div className="text-center">
        <h1>
          {'What do you do best?'} <br />
          {'You can select more than one.'}
        </h1>

        <div className="mt-10 md:mt-20">
          <ToggleGroup
            type="multiple"
            value={services}
            onValueChange={(value: ServiceType[]) => {
              designerCreateState$.services.set(value);
            }}
          >
            <ToggleGroupItem value="BRAND">Brand</ToggleGroupItem>
            <ToggleGroupItem value="PRODUCT">Product</ToggleGroupItem>
            <ToggleGroupItem value="WEBSITE">Website</ToggleGroupItem>
          </ToggleGroup>
        </div>

        <BottomNavigation
          middle={
            services.length > 0 && (
              <Button asChild>
                <Link to={'/new/designer/samples'}>Next</Link>
              </Button>
            )
          }
          left={
            <Button type="button" size={'sm'} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
