import { useInfiniteQuery } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { trpcClientUtils } from '@/api/trpc';
import { FounderItem } from '@/components/admin/founder-item';
import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { LinkButton } from '@/components/link-button';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import {
  founderGrayStatuses,
  founderGreenStatuses,
  founderHalfYellowStatuses,
  founderRedStatuses,
  founderYellowStatuses,
} from '@/lib/constants';
import { Founder } from '@/lib/global-types';

import { Filter } from '../_admin.index';

export const FOUNDER_FILTERS: Record<Filter, Founder['status'][]> = {
  next: [
    ...founderGrayStatuses,
    ...founderHalfYellowStatuses,
    ...founderYellowStatuses,
  ],
  past: founderRedStatuses,
  present: founderGreenStatuses,
};

export const Route = createFileRoute('/dashboard/admin/founders/_founders/')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      filter: search.filter as Filter | undefined,
    };
  },
  loaderDeps: ({ search: { filter } }) => ({ filter }),
  loader: async ({ deps: { filter } }) => {
    const statuses = filter ? FOUNDER_FILTERS[filter] : FOUNDER_FILTERS.next;

    const founders = await trpcClientUtils.admin.founder.list.fetch({
      statuses,
      limit: 10,
      offset: 0,
    });

    const countList = await trpcClientUtils.admin.founder.statusCounts.fetch();

    return { founders, countList };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { founders, countList } = Route.useLoaderData();
    const { filter } = Route.useSearch();
    const { ref, inView } = useInView();

    const statuses = filter ? FOUNDER_FILTERS[filter] : FOUNDER_FILTERS.next;
    const filters = FOUNDER_FILTERS[filter as keyof typeof FOUNDER_FILTERS];

    const currentFilterTotalCount = Object.entries(countList).reduce(
      (prevItem, [key, value]) => {
        if (filters.includes(key as Founder['status'])) {
          return prevItem + value;
        } else {
          return prevItem;
        }
      },
      0,
    );

    const founderQuery = useInfiniteQuery({
      queryKey: ['founders', statuses],
      queryFn: ({ pageParam }) => {
        return trpcClientUtils.admin.founder.list.fetch({
          statuses,
          limit: 10,
          offset: pageParam,
        });
      },
      initialData: () => ({
        pageParams: [0],
        pages: [founders],
      }),
      initialPageParam: 0,
      getNextPageParam: (_, allPages) => {
        const total = allPages.flat().length;

        if (total < currentFilterTotalCount) {
          return total;
        }

        return undefined;
      },
    });

    const flatFounders = founderQuery.data.pages.flat();

    useEffect(() => {
      if (inView) {
        founderQuery.fetchNextPage();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, founderQuery.data.pageParams]);

    return (
      <div className="flex flex-col items-center pb-40">
        <span>{currentFilterTotalCount} Founders</span>

        <div className="mx-5 flex max-w-[1080px] flex-wrap justify-center gap-4 pt-20 md:mx-10">
          {filter === 'next' && (
            <motion.div
              animate={{ y: 0, opacity: 1, scale: 1 }}
              initial={{ y: 10, opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2, delay: 0.05 }}>
              <LinkButton
                className="size-[224px]"
                to="/dashboard/admin/create-founder">
                Add
              </LinkButton>
            </motion.div>
          )}
          {flatFounders.map((founder, index) => (
            <motion.div
              key={index}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              initial={{ y: 10, opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2, delay: (index + 1) * 0.05 }}>
              <FounderItem founder={founder} key={founder.id} />
            </motion.div>
          ))}
        </div>

        {founderQuery.hasNextPage && (
          <div ref={ref} className="mt-10 text-center">
            <Spinner />
          </div>
        )}

        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to=".." search={{ filter }}>
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
