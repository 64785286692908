import '@/store/setup';
import '@/lib/utils/popstate-direction';

import { observer } from '@legendapp/state/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import dayjs from 'dayjs';
import advencedFormat from 'dayjs/plugin/advancedFormat';
import timezoneplugin from 'dayjs/plugin/timezone';
import utcplugin from 'dayjs/plugin/utc';

import { trpc, trpcClient } from '@/api/trpc';
import { queryClient } from '@/lib/query-client';
import { initUmami } from '@/lib/umami';

import { appRouter } from './router';
import { direction$ } from './store/page.state';

initUmami();
dayjs.extend(utcplugin);
dayjs.extend(timezoneplugin);
dayjs.extend(advencedFormat);

window.addEventListener('forward', () => {
  direction$.set('forwards');
});

window.addEventListener('back', () => {
  direction$.set('backwards');

  setTimeout(() => {
    direction$.set('forwards');
  }, 500);
});

const _Bootstrap = () => {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={appRouter} />
      </QueryClientProvider>
    </trpc.Provider>
  );
};

export const Bootstrap = observer(_Bootstrap);
