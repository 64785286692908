import { FoundersTag } from '@/components/founders-tag';
import { Button } from '@/components/ui/button';
import { useFounderInfo } from '@/hooks/use-founder-info';
import { Project } from '@/lib/global-types';
import { priceFormatter } from '@/lib/utils';

type Props = {
  project: Project;
  onClick?: () => void;
};
export const ProjectItem = ({ project, onClick }: Props) => {
  const founder = project.founder;

  const { price } = useFounderInfo(founder);

  return (
    <Button size={'lg'} asChild onClick={onClick}>
      <div className="flex flex-col items-center justify-center gap-4 text-center">
        <div>
          <h2 className="text-ellips max-h-7 overflow-hidden break-all">
            {founder.company_name}
          </h2>
          <h1>{founder.name}</h1>
        </div>
        <FoundersTag founder={founder} className="scale-75" />

        <h3>{priceFormatter.format(price)}</h3>
      </div>
    </Button>
  );
};
