import {
  DesignerAvailability,
  DesignerLevel,
  DesignerService,
  DesignerStatus,
} from '@prisma/client';
import { z } from 'zod';

export const designerServiceSchema = z.enum([
  'BRAND',
  'PRODUCT',
  'WEBSITE',
]) satisfies z.Schema<DesignerService>;

export const designerAvailabilitySchema = z.enum([
  'NEXT_WEEK',
  'NEXT_MONTH',
  'LATER',
]) satisfies z.Schema<DesignerAvailability>;

export const designerLevelSchema = z.enum([
  'ASSOCIATE',
  'LEAD',
  'DIRECTOR',
]) satisfies z.Schema<DesignerLevel>;

export const designerStatusSchema = z.enum([
  'ONBOARDING',
  'ONBOARDED',

  'INTRO_CALL_REQUEST_SENT',
  'INTRO_CALL_BOOKED',
  'INTRO_CALL_STARTED',
  'INTRO_CALL_ENDED',

  'PROJECT_ASSIGNED',

  'REJECTED',
]) satisfies z.Schema<DesignerStatus>;

export const designerCreateSchema = z.object({
  email: z.string().email(),
  services: z.array(designerServiceSchema),
  availability: designerAvailabilitySchema,
  links: z.array(z.string()),
  samples: z.array(z.string()),
});

export const designerOnboardSchema = z.object({
  name: z.string(),
  level: designerLevelSchema,
  time_zone: z.string(),
});

export const designerUpdateSubscriptionInputSchema = z.object({
  id: z.number(),
});
