import { createFileRoute, Link, redirect } from '@tanstack/react-router';

import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { EstimatedStartDate } from '@/lib/global-types';
import {
  designerCreateState$,
  isDesignerCreateCompleted$,
} from '@/store/designer.state';

export const Route = createFileRoute('/new/designer/time')({
  beforeLoad: () => {
    if (!isDesignerCreateCompleted$.peek()) {
      return redirect({ to: '/new/designer' });
    }
  },
  component: function Page() {
    const availability = designerCreateState$.availability.get();
    const navigate = Route.useNavigate();

    return (
      <div>
        <section className="flex flex-col items-center justify-center text-center">
          <h1>When would you like to begin?</h1>

          <ToggleGroup
            className="mt-10 md:mt-20"
            type="single"
            defaultValue={availability!}
            onValueChange={value => {
              if (value) {
                designerCreateState$.availability.set(
                  value as EstimatedStartDate,
                );

                navigate({ to: '/new/designer/email' });
              }
            }}
          >
            <ToggleGroupItem value="NEXT_WEEK">Next Week</ToggleGroupItem>
            <ToggleGroupItem value="NEXT_MONTH">Next Month</ToggleGroupItem>
            <ToggleGroupItem value="LATER">Later</ToggleGroupItem>
          </ToggleGroup>
        </section>

        <BottomNavigation
          left={
            <Button size="sm">
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
