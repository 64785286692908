import { observable } from '@legendapp/state';

import { TRPCRouterInput } from '@/lib/global-types';

export type DesignerCreateInput = TRPCRouterInput['designer']['create'];

export type CreativesTiers = 'L1' | 'L2' | 'L3';

export type DesignerCreate = {
  services: DesignerCreateInput['services'];
  samples: DesignerCreateInput['samples'];
  email: DesignerCreateInput['email'];
  links: DesignerCreateInput['links'];
  availability: DesignerCreateInput['availability'] | null;
};

export const designerCreateState$ = observable<DesignerCreate>({
  email: '',
  samples: [],
  services: [],
  links: [],
  availability: null,
});

export const isDesignerCreateCompleted$ = observable(() => {
  return (
    designerCreateState$.services.get().length > 0 ||
    designerCreateState$.samples.get().length > 0 ||
    designerCreateState$.email.get().length > 0
  );
});
