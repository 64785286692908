import { createFileRoute, redirect } from '@tanstack/react-router';

import { trpc } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  designerCreateState$,
  isDesignerCreateCompleted$,
} from '@/store/designer.state';

export const Route = createFileRoute('/new/designer/email')({
  beforeLoad: () => {
    if (!isDesignerCreateCompleted$.peek()) {
      return redirect({ to: '/new/designer' });
    }
  },
  component: function Page() {
    const email = designerCreateState$.email.get();
    const navigate = Route.useNavigate();

    const saveMutation = trpc.designer.create.useMutation();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const designer = designerCreateState$.peek();
      await saveMutation.mutateAsync({
        email: designer.email,
        samples: designer.samples,
        services: designer.services,
        links: designer.links,
        availability: designer.availability!,
      });

      navigate({ to: '/new/designer/check-mail' });
    };

    return (
      <form onSubmit={handleSubmit}>
        <section className="flex flex-col items-center justify-center text-center">
          <h1>What’s your email?</h1>

          <Input
            className="mt-10 md:mt-20"
            autoFocus
            name="email"
            id="email"
            type={'email'}
            onChange={e => {
              designerCreateState$.email.set(e.target.value);
            }}
            placeholder={'@'}
            value={email}
            size={1}
          />
        </section>

        <BottomNavigation
          middle={
            email && (
              <Button type="submit" loading={saveMutation.isPending}>
                Submit
              </Button>
            )
          }
          left={
            <Button type="button" disabled={saveMutation.isPending} size={'sm'}>
              <IconBackArrow />
            </Button>
          }
        />
      </form>
    );
  },
});
