'use client';

import { useRouter } from '@tanstack/react-router';

import { direction$ } from '@/store/page.state';

import { IconBackArrow } from './icons/icon-back-arrow';
import { LinkButton, LinkButtonProps } from './link-button';

interface BackButtonProps extends Omit<LinkButtonProps, 'children'> {
  icon?: React.ReactNode;
  native?: boolean;
}

export const BackButton = ({
  icon = <IconBackArrow />,
  to = '..',
  onClick,
  native = false,
  ...props
}: BackButtonProps) => {
  const { history } = useRouter();
  const changeDirection = (e: never) => {
    if (native) {
      //@ts-expect-error - e.preventDefault() is not available on all events
      e.preventDefault();
      history.back();
    }
    direction$.set('backwards');
    setTimeout(() => {
      direction$.set('forwards');
    }, 500);
    onClick?.(e);
  };

  return (
    <LinkButton
      to={to}
      size="sm"
      {...props}
      preload={false}
      onClick={changeDirection}>
      {icon}
    </LinkButton>
  );
};
