import { Option, PLAN_WITH_SERVICES } from '@op-platform/backend/founder';
import { useMemo } from 'react';

import { SERVICE_SIZE_VALUES } from '@/lib/constants';
import { convertServicesForUI } from '@/lib/utils';

export const useOption = (option: Option | null) => {
  const allServices = useMemo(() => {
    if (!option) return [];
    if (option.plan)
      return convertServicesForUI(PLAN_WITH_SERVICES[option.plan]);
    return convertServicesForUI(option.services);
  }, [option]);

  const maxDurationService = allServices.sort(
    (a, b) => SERVICE_SIZE_VALUES[b.size] - SERVICE_SIZE_VALUES[a.size],
  )[0]!;

  const price = useMemo(() => {
    return allServices.reduce((acc, service) => acc + service.price, 0);
  }, [allServices]);

  return {
    option,
    allServices,
    maxDurationService,
    price,
  };
};
