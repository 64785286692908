import { createFileRoute, Link } from '@tanstack/react-router';

import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { founderOnboardingState$ } from '@/store/founder.state';

export const Route = createFileRoute('/onboarding/founder/')({
  component: function Page() {
    const name = founderOnboardingState$.name.get();
    const navigate = Route.useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      navigate({
        to: '/onboarding/founder/timezone',
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center"
      >
        <h1>
          {'Welcome to OP.'} <br />
          {'What should we call you?'}
        </h1>

        <div className="mt-20">
          <Input
            autoFocus
            type={'text'}
            onChange={e => {
              founderOnboardingState$.name.set(e.target.value);
            }}
            placeholder={'Your name'}
            value={name}
            size={1}
          />
        </div>

        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to="/">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={name && <Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
