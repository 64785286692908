import type { AppRouter } from '@op-platform/backend';
import {
  createTRPCClient,
  CreateTRPCClientOptions,
  httpBatchLink,
  splitLink,
  unstable_httpSubscriptionLink,
} from '@trpc/client';
import { createTRPCQueryUtils, createTRPCReact } from '@trpc/react-query';
import superjson from 'superjson';

import { BACKEND_URL, SYSTEM_TIME } from '@/lib/constants';
import { queryClient } from '@/lib/query-client';

const getHeaders = () => {
  const cookieStore = window.localStorage;
  const accessToken = cookieStore.getItem('accessToken') || '';
  const debugTime = SYSTEM_TIME().toISOString();

  const commonHeaders = {
    /** Other headers */
  };

  return {
    Authorization: `Bearer ${accessToken}`,
    'op-systemtime-debug': debugTime,
    ...commonHeaders,
  };
};

const trpcOptions: CreateTRPCClientOptions<AppRouter> = {
  links: [
    splitLink({
      condition: op => op.type === 'subscription',
      true: unstable_httpSubscriptionLink({
        url: `${BACKEND_URL}/trpc`,
        transformer: superjson,
        connectionParams: () => {
          return {
            token: getHeaders().Authorization,
          };
        },
      }),
      false: httpBatchLink({
        url: `${BACKEND_URL}/trpc`,
        transformer: superjson,
        headers: async () => {
          // TODO: add authentication headers
          return {
            ...getHeaders(),
          };
        },
      }),
    }),
  ],
};

export const trpc = createTRPCReact<AppRouter>();
export const trpcClient = trpc.createClient(trpcOptions);
export const trpcClientUtils = createTRPCQueryUtils({
  queryClient,
  client: trpcClient,
});

export const trpcVanilla = createTRPCClient<AppRouter>(trpcOptions);
