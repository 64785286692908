export const OPLogoLarge = () => {
  return (
    <h2 className="flex h-6 items-start justify-start">
      <span className="text-[24px] leading-none">OpenPurpose</span>
      <span className="text-[10px] font-light leading-none">®</span>
    </h2>
  );
};

OPLogoLarge.displayName = 'OPLogoLarge';
