'use client';
import { ChangeEventHandler, useRef, useState } from 'react';

import { trpc } from '@/api/trpc';
import { Input } from '@/components/ui/input';
import { useDebounce } from '@/hooks/use-debounce';
import { TRPCRouterOutput } from '@/lib/global-types';
import { cn, formatTimezone } from '@/lib/utils';

import { Spinner } from './spinner';

interface Props {
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: (timeZone: string, city: string) => void;
  fetchOnMount?: boolean;
}

export const TimezoneSelector = ({
  placeholder,
  className,
  defaultValue = '',
  onChange,
  fetchOnMount = false,
}: Props) => {
  const [value, setValue] = useState(defaultValue);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [enableQuery, setEnableQuery] = useState(fetchOnMount);
  const debounceSearch = useDebounce(value, 500);

  const citiesQuery = trpc.misc.cities.useQuery(
    {
      cityQuery: debounceSearch.debouncedValue,
    },
    {
      enabled: enableQuery,
    },
  );

  const citiesList = citiesQuery.data || [];

  const getLabel = (city: TRPCRouterOutput['misc']['cities'][number]) =>
    `${city.name}, ${city.country_name_en} ･ UTC ${city.gmt_offset >= 0 ? '+' : ''}${Math.round(city.gmt_offset / 3600)}`;

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.currentTarget.value);
    onChange('', '');
    setEnableQuery(true);
  };

  const handleItemClick = (city: string, timezone: string) => {
    setValue(city);
    onChange(timezone, city);
    setEnableQuery(false);
  };

  const renderCityList = () => {
    if (!citiesQuery.isStale) return null;

    if (citiesQuery.isPending) {
      return (
        <div className="p-10">
          <Spinner />
        </div>
      );
    }

    if (!citiesQuery.isFetched) return null;

    if (citiesQuery.isError) {
      return (
        <div className="p-10">
          <p>Something went wrong</p>
        </div>
      );
    }

    if (citiesList.length === 0) {
      return (
        <div className="p-10">
          <p>No such city available</p>
        </div>
      );
    }

    return citiesList.map(city => (
      <button
        key={city.geoname_id}
        className="flex min-h-10 items-center rounded-full px-4 py-1 text-tertiary transition-colors hover:bg-primary hover:text-white focus:outline-none focus-visible:bg-primary focus-visible:text-white"
        onClick={() =>
          handleItemClick(getLabel(city), formatTimezone(city.timezone))
        }>
        {getLabel(city)}
      </button>
    ));
  };

  return (
    <div className={cn('relative flex flex-col items-center gap-4', className)}>
      <Input
        autoFocus
        value={value || ''}
        onChange={handleChange}
        placeholder={placeholder || 'Your city'}
      />
      <div
        ref={scrollContainerRef}
        onScroll={() => {
          if (!scrollContainerRef.current) return;
          const maxScrollTop =
            scrollContainerRef.current.scrollHeight -
            scrollContainerRef.current.clientHeight;

          setIsScrolled(scrollContainerRef.current.scrollTop >= 12);
          setIsScrolledToEnd(
            scrollContainerRef.current.scrollTop >= maxScrollTop,
          );
        }}
        className="no-scrollbar flex max-h-[280px] flex-col items-center gap-2 overflow-y-scroll"
        tabIndex={-1}>
        {renderCityList()}
      </div>

      {!citiesQuery.isPending && (
        <>
          <div
            className={cn(
              'pointer-events-none absolute left-0 right-0 top-14 h-20 bg-gradient-to-b from-white to-transparent opacity-0 transition-opacity',
              {
                'opacity-100': isScrolled,
              },
            )}></div>
          <div
            className={cn(
              'pointer-events-none absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent opacity-100 transition-opacity',
              {
                'opacity-0': isScrolledToEnd || citiesList.length < 6,
              },
            )}></div>
        </>
      )}
    </div>
  );
};

TimezoneSelector.displayName = 'TimezoneSelector';
