import slugify from 'slugify';

import { Project } from '../project/constants';
import { User } from '../user/constants';

export const ADMIN_CHAT_CHANNEL_ID = () =>
  `op-admins-chat-${process.env.APP_ENV}`;

export const generateChatUserId = (user: User) => {
  const username = user.email.split('@')[0]!;

  return `${user.user_type}-${slugify(username, {
    strict: true,
  })}`;
};

export const generateChannelId = (project: Project) => {
  return `project-${project.slug}`;
};
