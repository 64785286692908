import { useMemo, useRef } from 'react';

import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import { unFormatTimezone } from '@/lib/utils';

export const useReviewTimesForDesigner = (
  timeZone: string,
  targetTimeZone: string,
) => {
  const tzTime = useTimeZoneClock(unFormatTimezone(timeZone));

  const tzTimeRef = useRef(tzTime);

  const isAllowedSending = useMemo(() => {
    // designers can only update on monday, tuesday, wednesday, thursday between 3pm and 4pm

    const currentDay = tzTime.day();
    const currentTime = tzTime.hour();

    if (currentDay >= 1 && currentDay <= 4) {
      return currentTime >= 15 && currentTime < 16;
    }

    return false;
  }, [tzTime]);

  const startDate = useMemo(() => {
    // startDate should be the previous closest monday, tuesday, wednesday, thursday at 3 PM
    let date = tzTimeRef.current.clone();
    const currentDay = date.day();
    const currentHour = date.hour();

    switch (currentDay) {
      case 1:
      case 2:
      case 3:
      case 4: {
        if (currentHour >= 15) {
          break;
        } else if (currentDay === 1) {
          // monday
          // last thursday
          date = date.add(-4, 'day');
        } else {
          // tuesday
          // last thursday
          date = date.add(-1, 'day');
        }
        break;
      }
      case 5:
        // friday
        // last thursday
        date = date.add(-1, 'day');
        break;
      case 6:
        // saturday
        // last thursday
        date = date.add(-2, 'day');
        break;
      case 0:
        // sunday
        // last thursday
        date = date.add(-3, 'day');
        break;
    }

    return date.hour(16).minute(0).second(0).millisecond(0);
  }, []);

  const endDate = useMemo(() => {
    // endDate should be the next closest monday, tuesday, wednesday, thursday at 4 PM
    let date = tzTimeRef.current.clone();
    const currentDay = date.day();
    const currentHour = date.hour();

    switch (currentDay) {
      case 1:
      case 2:
      case 3:
      case 4: {
        if (currentHour < 16) {
          break;
        } else if (currentDay === 4) {
          // thursday
          // next monday
          date = date.add(3, 'day');
        } else {
          // monday
          // next tuesday
          date = date.add(1, 'day');
        }
        break;
      }
      case 5:
        // friday
        // next monday
        date = date.add(3, 'day');
        break;
      case 6:
        // saturday
        // next monday
        date = date.add(2, 'day');
        break;
      case 0:
        // sunday
        // next monday
        date = date.add(1, 'day');
        break;
    }

    return date.hour(15).minute(0).second(0).millisecond(0);
  }, []);

  const nextReviewText = useMemo(() => {
    const targetTimeZoneDate = endDate
      .clone()
      .tz(unFormatTimezone(targetTimeZone));

    const day = targetTimeZoneDate.format('dddd');

    return (
      <>
        Next Review on <br />
        {day} {targetTimeZoneDate.format('HH:mm')}
      </>
    );
  }, [endDate, targetTimeZone]);

  return {
    time: tzTime,
    startDate,
    endDate,
    isAllowedSending,
    nextReviewText,
  };
};
