export const PROJECTS = [
  {
    name: 'rala',
    type: 'B2C',
    description: ['Real time in-game assistant.'],
    link: {
      text: 'rala.ai',
      href: 'https://rala.ai',
    },
    step: '0 - 1',
    tags: ['B', 'W', 'P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'full', type: 'img' },
      { src: 'slide5.jpg', width: 'full', type: 'img' },
      { src: 'slide6.jpg', width: 'full', type: 'img' },
      { src: 'slide7.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'shop',
    type: 'B2C',
    description: ['Creative campaign for \nShop week.'],
    step: '1 - N',
    tags: ['W', 'P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'half', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.mp4', width: 'full', type: 'video' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'full', type: 'img' },
      { src: 'slide7.jpg', width: 'half', type: 'img' },
      { src: 'slide8.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'azura',
    type: 'web3',
    description: ['The fastest way\nto trade on chain.'],
    link: {
      text: 'azura.xyz',
      href: 'https://azura.xyz',
    },
    step: '0 - 1',
    tags: ['B', 'W', 'P'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
      { src: 'slide7.jpg', width: 'full', type: 'img' },
      { src: 'slide8.jpg', width: 'full', type: 'img' },
      { src: 'slide9.jpg', width: 'full', type: 'img' },
      { src: 'slide10.jpg', width: 'half', type: 'img' },
      { src: 'slide11.jpg', width: 'half', type: 'img' },
      { src: 'slide12.jpg', width: 'half', type: 'img' },
      { src: 'slide13.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'caldera',
    type: 'web3',
    description: ['The everything layer\nfor rollups.'],
    link: {
      text: 'caldera.xyz',
      href: 'https://caldera.xyz',
    },
    step: '0 - 1',
    tags: ['B', 'W'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'full', type: 'img' },
      { src: 'slide7.jpg', width: 'full', type: 'img' },
      { src: 'slide8.jpg', width: 'half', type: 'img' },
      { src: 'slide9.jpg', width: 'half', type: 'img' },
      { src: 'slide10.jpg', width: 'half', type: 'img' },
      { src: 'slide11.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'tlon',
    type: 'B2C',
    description: ['Authentic connections online.'],
    link: {
      text: 'tlon.io',
      href: 'https://tlon.io',
    },
    step: '1 - N',
    tags: ['W', 'P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'vital',
    type: 'B2C',
    description: ['The social layer\nfor health, Vital.'],
    step: '0 - 1',
    tags: ['B', 'W', 'P'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
      { src: 'slide7.jpg', width: 'half', type: 'img' },
      { src: 'slide8.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'officehours',
    type: 'B2C',
    description: ['Office Hours.\n A new publication from Edouard Dessaint.'],
    step: '0 - 1',
    tags: ['B'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'valhalla',
    type: 'web3',
    description: ['Valhalla.\nThe Perpetuals exchange \non MegaETH.'],
    step: '0 - 1',
    tags: ['B', 'W', 'P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
      { src: 'slide4.jpg', width: 'full', type: 'img' },
      { src: 'slide5.jpg', width: 'full', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
      { src: 'slide7.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'obscura',
    type: 'B2C',
    description: ['Privacy made private\nwith Obscura.'],
    step: '0 - 1',
    tags: ['B'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'half', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'krea',
    type: 'B2C',
    description: ['Ai as your creative partner.'],
    link: {
      text: 'krea.ai',
      href: 'https://krea.ai',
    },
    step: '0 - 1',
    tags: ['P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'offtop',
    type: 'B2C',
    description: ['Share and collaborate\non your music with OffTop.'],
    step: '0 - 1',
    tags: ['P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'plasticity',
    type: 'B2B',
    description: ['CAD for artists.'],
    link: {
      text: 'plasticity.xyz',
      href: 'https://plasticity.xyz',
    },
    step: '1 - N',
    tags: ['P', 'E'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'mobbin',
    type: 'B2C',
    description: ['Preparing Mobbin for scale\nwith a new design system.'],
    link: {
      text: 'mobbin.com',
      href: 'https://mobbin.com',
    },
    step: '1 - N',
    tags: ['P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'pakt',
    type: 'B2B',
    description: ['A social brand\nfor global impact.'],
    step: '0 - 1',
    tags: ['B'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'userfaces',
    type: 'web3',
    description: ['Home to Creatives.'],
    link: {
      text: 'userfaces.xyz',
      href: 'https://userfaces.xyz',
    },
    step: '0 - 1',
    tags: ['B'],
    duration: 1,
    media: [
      { src: 'slide1.mp4', width: 'full', type: 'video' },
      { src: 'slide2.mp4', width: 'half', type: 'video' },
      { src: 'slide3.mp4', width: 'half', type: 'video' },
    ],
  },
  {
    name: 'asi',
    type: 'B2C',
    description: ['Ai as your new teacher.\nFor schools in UAE.'],
    link: {
      text: 'asi.tech',
      href: 'https://asi.tech',
    },
    step: '0 - 1',
    tags: ['P'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'calendar',
    type: 'B2B',
    description: ['A new calendar to bring \ntransparency to enterprises.'],
    step: 'N - XYZ',
    tags: ['P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'impower',
    type: 'B2B',
    description: [
      'Leading all-in-one property \nmanagement solution in Germany.',
    ],
    link: {
      text: 'impower.de',
      href: 'https://impower.de',
    },
    step: '0 - 1',
    tags: ['P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
      { src: 'slide4.jpg', width: 'full', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'stacker',
    type: 'B2B',
    description: ['Growing Stacker with a new look.'],
    link: {
      text: 'stackerhq.com',
      href: 'https://stackerhq.com',
    },
    step: '1 - N',
    tags: ['W'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'inen',
    type: 'B2C',
    description: ['Where creatives find jobs.'],
    step: '0 - 1',
    tags: ['B', 'P'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'vfs',
    type: 'B2C',
    description: [
      'Digitalising the visa system\nthat runs the traveling globally.',
    ],
    link: {
      text: 'vfsglobal.com',
      href: 'https://www.vfsglobal.com/en/governments/index.html',
    },
    step: 'N - XYZ',
    tags: ['P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'thinkeo',
    type: 'B2B',
    description: ['Content creation\nin scale for enterprises.'],
    step: '0 - 1',
    tags: ['P', 'E'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'sonantic',
    type: 'B2B',
    description: ['Turn text into real voices.\nAcquired by Spotify.'],
    step: '0 - 1',
    tags: ['P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'coco',
    type: 'B2C',
    description: ['The only app you need\nto run your small business.'],
    step: '0 - 1',
    tags: ['B', 'P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.mp4', width: 'full', type: 'video' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
      { src: 'slide7.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'fluorescent',
    type: 'B2B',
    description: ['Everything you need\nto succeed on Shopify.'],
    link: {
      text: 'fluorescent.co',
      href: 'https://fluorescent.co',
    },
    step: '1 - N',
    tags: ['B', 'W'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'tab',
    type: 'B2C',
    description: ['A new AI-friend.'],
    link: { text: 'friend.com', href: 'https://friend.com' },
    step: '0 - 1',
    tags: ['P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'opal',
    type: 'web3',
    description: ['Limit your screen-time.'],
    link: {
      text: 'opal.so',
      href: 'https://opal.so',
    },
    step: '1 - N',
    tags: ['P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'half', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'amie',
    type: 'B2C',
    description: ['A new calendar app\ninspired by our childhood.'],
    link: {
      text: 'amie.so',
      href: 'https://amie.so',
    },
    step: '1 - N',
    tags: ['B'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.mp4', width: 'full', type: 'video' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
      { src: 'slide7.mp4', width: 'half', type: 'video' },
      { src: 'slide8.mp4', width: 'half', type: 'video' },
    ],
  },
  {
    name: 'furniture',
    type: 'B2C',
    description: ['Furniture made playful.'],
    step: '0 - 1',
    tags: ['P'],
    duration: 2,
    media: [{ src: 'slide1.mp4', width: 'full', type: 'video' }],
  },
  {
    name: 'haus',
    type: 'B2C',
    description: ['A new bank is is opening soon.'],
    step: '0 - 1',
    tags: ['B'],
    duration: 1,
    media: [{ src: 'slide1.jpg', width: 'full', type: 'img' }],
  },
  {
    name: 'compliment',
    type: 'B2B',
    description: ['A multi-purpose\ncreative space.'],
    step: '0 - 1',
    tags: ['B', 'P', 'E'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'half', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
      { src: 'slide4.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'semantical',
    type: 'B2C',
    description: [
      "A new brand\nmade in the stars\nfor CulturedCode's founder.",
    ],
    step: '0 - 1',
    tags: ['B', 'W'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'paradigm',
    type: 'B2B',
    description: [
      'An all-in-one SaaS app to run\nlarge scale VC firms with ease.',
    ],
    step: '0 - 1',
    tags: ['P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
      { src: 'slide4.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'cyber',
    type: 'B2C',
    description: ['The L2 for social.'],
    link: {
      text: 'cyber.co',
      href: 'https://cyber.co',
    },
    step: '1 - N',
    tags: ['B', 'W'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'half', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'console',
    type: 'B2B',
    description: ['Automate your help desk with \nAI-powered IT agents.'],
    link: {
      text: 'console.co',
      href: 'https://console.co',
    },
    step: '0 - 1',
    tags: ['B', 'P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'covertree',
    type: 'B2C',
    description: ['Keeping you covered\nwith Covertree.'],
    step: '1 - N',
    tags: ['B', 'W'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'echolabs',
    type: 'B2B',
    description: ['AI accessibility tools\nthat save schools millions.'],
    link: {
      text: 'el.ai',
      href: 'https://el.ai',
    },
    step: '0 - 1',
    tags: ['B', 'W'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'multi',
    type: 'web3',
    description: ['Secure your wallet.'],
    link: {
      text: 'multi.inc',
      href: 'https://multi.inc',
    },
    step: '0 - 1',
    tags: ['P'],
    duration: 3,
    media: [
      { src: 'slide1.jpg', width: 'half', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'marviaca',
    type: 'B2C',
    description: ['A new streetwear brand\nfor an Instagram influencer.'],
    step: '0 - 1',
    tags: ['B', 'W'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'reserved',
    type: 'B2C',
    description: ['High-end retail\nmade social.'],
    step: '0 - 1',
    tags: ['B'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
    ],
  },
  {
    name: 'caffe-38',
    type: 'B2C',
    description: ['Experience home at \nCaffe 38 1.'],
    step: '0 - 1',
    tags: ['B'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'half', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'ofc',
    type: 'web3',
    description: ['NFTs to replace\nregular SaaS subscriptions.'],
    step: '0 - 1',
    tags: ['B'],
    duration: 3,
    media: [
      { src: 'slide1.mp4', width: 'full', type: 'video' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'renklar',
    type: 'B2C',
    description: ['Self-care with self-love.'],
    step: '0 - 1',
    tags: ['B', 'W', 'P'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'full', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'popcorn',
    type: 'B2C',
    description: ['One global plan.'],
    link: {
      text: 'popcorn.space',
      href: 'https://popcorn.space',
    },
    step: '0 - 1',
    tags: ['B'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'full', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
      { src: 'slide7.jpg', width: 'half', type: 'img' },
      { src: 'slide8.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'defi',
    type: 'web3',
    description: [
      "Combining the magic of CeFi and DeFi in\nthe world's first decentralised SuperApp.",
    ],
    link: {
      text: 'defi.app',
      href: 'https://defi.app/',
    },
    step: '0 - 1',
    tags: ['B', 'W'],
    duration: 2,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'full', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
    ],
  },
  {
    name: 'push',
    type: 'B2C',
    description: [
      'Build muscle fast with AI, \nbacked by the latest science.',
      'Coming soon.',
    ],
    link: {
      text: 'pushapp.co.uk',
      href: 'https://pushapp.co.uk',
    },
    step: '0 - 1',
    tags: ['B', 'P'],
    duration: 1,
    media: [
      { src: 'slide1.jpg', width: 'full', type: 'img' },
      { src: 'slide2.jpg', width: 'half', type: 'img' },
      { src: 'slide3.jpg', width: 'half', type: 'img' },
      { src: 'slide4.jpg', width: 'half', type: 'img' },
      { src: 'slide5.jpg', width: 'half', type: 'img' },
      { src: 'slide6.jpg', width: 'half', type: 'img' },
      { src: 'slide7.jpg', width: 'half', type: 'img' },
      { src: 'slide8.jpg', width: 'half', type: 'img' },
      { src: 'slide9.jpg', width: 'half', type: 'img' },
    ],
  },
];
