import { createFileRoute, Link } from '@tanstack/react-router';

import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';

export const Route = createFileRoute('/new/designer/check-mail')({
  component: function Page() {
    return (
      <div className="text-center">
        <h1 className="mt-10 md:mt-20">
          Check your inbox. <br /> It holds a magic link.
        </h1>

        <BottomNavigation
          left={
            <Button>
              <Link to="/">Close</Link>
            </Button>
          }
        />
      </div>
    );
  },
});
