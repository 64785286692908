import { useState } from 'react';

import { CalcomEmbed } from '@/components/calcom-embed';
import { SlideContainer } from '@/components/slide-container';
import { Button } from '@/components/ui/button';
import { Founder } from '@/lib/global-types';

type Props = {
  founder: Omit<Founder, 'bookings' | 'invoice' | 'project'>;
};
export const KickOffCallRequest = ({ founder }: Props) => {
  const [step, setStep] = useState<'review' | 'bookACall'>('review');

  const direction = step === 'review' ? 'forwards' : 'backwards';

  return (
    <SlideContainer
      className="flex flex-col items-center"
      animationKey={step}
      direction={direction}>
      <p className="mb-10 flex text-center">
        {`Let's`} talk about your project, {founder.name}! <br />
      </p>

      {step === 'review' && (
        <Button size="lg" onClick={() => setStep('bookACall')}>
          Book a kickoff call
        </Button>
      )}
      {step === 'bookACall' && (
        <div className="min-w-[380px] pb-40 md:min-w-[480px]">
          <CalcomEmbed
            name={founder.name!}
            email={founder.user.email}
            userType="FOUNDER"
            callType="KICKOFF_CALL"
          />
        </div>
      )}
    </SlideContainer>
  );
};
