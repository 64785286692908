import { createFileRoute, redirect } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { guardRoute } from '@/lib/route.utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute(
  '/dashboard/project/$projectId/update/$updateNumber/feedback',
)({
  beforeLoad: () => guardRoute(['FOUNDER']),
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const update = project.updates.find(
      update => update.updateNumber === Number(params.updateNumber),
    );

    if (!update) {
      toast("Couldn't find the update");
      throw redirect({
        to: '/dashboard/project/$projectId',
        params: { projectId: project.id.toString() },
        throw: true,
        replace: true,
      });
    }

    return { project, update };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const [feedback, setFeedback] = useState('');
  const { project, update } = Route.useLoaderData();
  const navigate = Route.useNavigate();

  const rateUpdateMutation = trpc.project.update.publishRating.useMutation();

  return (
    <>
      <div className="flex flex-col items-center gap-10">
        <h2>
          Write your feedback below. <br />
          Big picture first then details.
        </h2>
        <Textarea
          placeholder="Feedback.."
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
        />
      </div>

      <BottomNavigation
        left={<BackButton disabled={rateUpdateMutation.isPending} native />}
        middle={
          <Button
            loading={rateUpdateMutation.isPending}
            onClick={async () => {
              await rateUpdateMutation.mutateAsync({
                id: project.id,
                updateId: update.id,
                feedback,
              });

              toast('Feedback sent');
              navigate({
                to: `/dashboard/project/$projectId`,
              });
            }}>
            Send
          </Button>
        }
      />
    </>
  );
}
