import {
  createFileRoute,
  ErrorComponent,
  Link,
  redirect,
} from '@tanstack/react-router';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CoundownTimer } from '@/components/countdown-timer';
import { helpDialog$ } from '@/components/dialogs/common/help';
import { IntroCallRequest } from '@/components/founder/intro-call-request';
import { KickOffCallRequest } from '@/components/founder/kick-off-call-request';
import { SlideContainer } from '@/components/slide-container';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { useFounderStatusUpdate } from '@/hooks/use-websocket';
import { DAYS, IS_DEV } from '@/lib/constants';
import { toast } from '@/lib/utils/toast';

import { FounderStatusSchema } from '../../../../../backend/src/features/founder/validators';

export const Route = createFileRoute('/dashboard/founder/_founder/')({
  loader: async () => {
    const founder = await trpcClientUtils.founder.me.fetch(undefined, {
      staleTime: 0,
      gcTime: 0,
    });

    switch (founder.status) {
      case 'PROJECT_STARTED':
        throw redirect({
          to: `/dashboard/project/$projectId`,
          params: {
            projectId: founder.project!.id.toString(),
          },
          replace: true,
        });
    }
  },

  gcTime: 0,
  onError: error => {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        toast('You are not authorized to view this page.');
        throw redirect({ to: '/login', throw: true });
      }
    }
    return <ErrorComponent error={error} />;
  },

  pendingComponent: () => (
    <div className="grid h-[100dvh] w-[100dvw] place-items-center">
      <Spinner />
    </div>
  ),

  component: function Page() {
    const navigate = Route.useNavigate();
    const [founder, founderQuery] = trpc.founder.me.useSuspenseQuery();

    useFounderStatusUpdate(founder.id, () => founderQuery.refetch());

    const renderActionButton = () => {
      switch (founder.status) {
        case 'ONBOARDED':
          return (
            <>
              <p className="text-center">
                Thank you, {founder.name}. <br />
                We’ll send you an email within <br />
                24 hours to book a call.
              </p>

              <CoundownTimer
                startDate={dayjs(founder.user.updated_at)}
                finishDate={dayjs(founder.user.updated_at).add(24, 'hour')}
                timeZone={founder.time_zone!}>
                Reviewing...
              </CoundownTimer>
            </>
          );

        case 'INTRO_CALL_REQUEST_SENT':
          return (
            <IntroCallRequest
              userType="FOUNDER"
              email={founder.user.email}
              name={founder.name!}
              onSuccess={() => navigate({ to: '.' })}
            />
          );

        case 'INTRO_CALL_BOOKED': {
          const introCallBooking = founder.bookings.find(
            b => b.type === 'INTRO_CALL',
          );
          if (!introCallBooking) return;

          const firstCall = dayjs(introCallBooking.date);
          return (
            <>
              <p className="text-center">
                We’ll send you an email <br /> an hour before our call.
              </p>
              <CoundownTimer
                startDate={dayjs(introCallBooking.created_at)}
                finishDate={firstCall}
                timeZone={founder.time_zone!}>
                First sync on {DAYS[firstCall.day()]} <br />
                {firstCall.format('DD MMM HH:mm')}
              </CoundownTimer>
            </>
          );
        }

        case 'INTRO_CALL_STARTED': {
          const introCallBooking = founder.bookings.find(
            b => b.type === 'INTRO_CALL',
          );

          if (!introCallBooking) return;
          return (
            <>
              <p className="text-center">It`s time. See you there.</p>
              <Button size="lg" asChild>
                <a
                  href={`https://app.cal.com/video/${introCallBooking.calcom_id}`}
                  target="_blank"
                  rel="noreferrer">
                  Join the call
                </a>
              </Button>
            </>
          );
        }

        case 'INTRO_CALL_ENDED': {
          return (
            <Button disabled size="lg">
              Waiting for Options...
            </Button>
          );
        }

        case 'OPTIONS_SENT': {
          return (
            <>
              <p className="text-center">
                Your next step <br /> is here.
              </p>
              <Button size="lg" asChild>
                <Link to="/dashboard/founder/proposals">View Options</Link>
              </Button>
            </>
          );
        }

        case 'OPTIONS_CONFIRMED': {
          return (
            <Button size="lg" asChild>
              <Link to="/dashboard/founder/company-form">
                Enter Billing Details
              </Link>
            </Button>
          );
        }

        case 'INVOICE_SENT':
          return (
            <Button size="lg" asChild>
              <Link to="/dashboard/founder/invoice">View Invoice</Link>
            </Button>
          );

        case 'PAYMENT_MADE': {
          const invoice = founder.invoice!;
          const updatedAt = dayjs(invoice.updatedAt);

          return (
            <>
              <p className="text-center">
                Thank you. <br /> Give us a day <br /> to confirm payment.
              </p>
              <CoundownTimer
                startDate={updatedAt}
                finishDate={updatedAt.add(1, 'day')}
                timeZone={founder.time_zone!}>
                <p>Reviewing...</p>
              </CoundownTimer>
            </>
          );
        }

        case 'KICKOFF_CALL_REQUEST_SENT':
          return <KickOffCallRequest founder={founder} />;

        case 'KICKOFF_CALL_BOOKED': {
          const kickoffBooking = founder.bookings.find(
            b => b.type === 'KICKOFF_CALL',
          );

          if (!kickoffBooking) return;

          const kickoffCallDate = dayjs(kickoffBooking.date);

          return (
            <CoundownTimer
              startDate={dayjs(kickoffBooking.created_at)}
              finishDate={kickoffCallDate}
              timeZone={founder.time_zone!}>
              Kickoff call on {DAYS[kickoffCallDate.day()]} <br />
              {kickoffCallDate.format('DD MMM HH:mm')}
            </CoundownTimer>
          );
        }

        case 'KICKOFF_CALL_STARTED': {
          const kickoffBooking = founder.bookings.find(
            b => b.type === 'KICKOFF_CALL',
          );

          if (!kickoffBooking) return;

          return (
            <>
              <p className="text-center">It’s time. See you there.</p>
              <Button size="lg" asChild>
                <a
                  href={`https://app.cal.com/video/${kickoffBooking.calcom_id}`}
                  target="_blank"
                  rel="noreferrer">
                  Join the call
                </a>
              </Button>
            </>
          );
        }

        case 'KICKOFF_CALL_ENDED': {
          return (
            <Button disabled size="lg">
              Waiting to project start...
            </Button>
          );
        }

        case 'PROJECT_STARTED': {
          const project = founder.project;
          if (!project) return null;

          return (
            <Button size="lg" asChild>
              <Link
                to="/dashboard/project/$projectId"
                params={{ projectId: project.id.toString() }}>
                View Project
              </Link>
            </Button>
          );
        }
      }
    };

    return (
      <div className="flex flex-col items-center">
        <SlideContainer
          className="flex flex-col items-center gap-10 md:gap-20"
          animationKey={founder.status}>
          {renderActionButton()}
        </SlideContainer>

        {IS_DEV && (
          <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
            <span>email: {founder.user.email}</span>
            <span>name: {founder.name}</span>
            <span>company: {founder.company_name}</span>
            <span>id: {founder.id}</span>
            <span>status: {founder.status}</span>
            <span>time_zone: {founder.time_zone}</span>

            <h3 className="mt-2">Status Table</h3>
            <hr className="h-0.5 w-full bg-gray-600" />
            <ul className="text-left">
              {FounderStatusSchema.options.map(option => (
                <li
                  key={option}
                  className={
                    option === founder.status ? 'font-bold text-black' : ''
                  }>
                  {option === founder.status && '> '} {option}
                </li>
              ))}
            </ul>
          </code>
        )}

        <BottomNavigation
          right={
            <Button size={'sm'} onClick={() => helpDialog$.set(true)}>
              ?
            </Button>
          }
        />
      </div>
    );
  },
});
