import { PLAN_WITH_SERVICES } from '@op-platform/backend/founder';
import { getProjectDurationMonthFromOption } from '@op-platform/backend/founder/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { SYSTEM_TIME } from '@/lib/constants';
import { Project } from '@/lib/global-types';
import { convertServicesForUI } from '@/lib/utils';

const today = SYSTEM_TIME;

export const useProject = (project: Project) => {
  const founder = project.founder;

  const selectedOption = founder.selectedOption;

  const allServices = useMemo(() => {
    if (!selectedOption) return [];
    if (selectedOption.plan)
      return convertServicesForUI(PLAN_WITH_SERVICES[selectedOption.plan]);
    return convertServicesForUI(selectedOption.services);
  }, [selectedOption]);

  const startDate = dayjs(project.startDate);

  const endDate = useMemo(() => {
    if (!selectedOption) throw new Error('No selected option');
    const projectMaxDurationMonth =
      getProjectDurationMonthFromOption(selectedOption);

    return dayjs(startDate).add(projectMaxDurationMonth, 'month');
  }, [selectedOption, startDate]);

  const isStarted = today().isAfter(startDate);

  return {
    allServices,
    startDate,
    endDate,
    isStarted,
  };
};
