import { isCurrentReview } from '@op-platform/backend/project/utils';
import { createFileRoute, Link } from '@tanstack/react-router';
import dayjs from 'dayjs';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { ReviewItem } from '@/components/project/review-item';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';

export const Route = createFileRoute('/dashboard/project/$projectId/reviews')({
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    return { project };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { project } = Route.useLoaderData();
  const time = useTimeZoneClock(project.founder.time_zone!);

  const reviews = project.reviews
    .filter(review => {
      return (
        dayjs(review.date).isBefore(time) ||
        isCurrentReview(dayjs(review.date), time)
      );
    })
    .sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));

  return (
    <main className="pb-24">
      <div className="flex flex-col items-center gap-4">
        {reviews.length > 0 ? (
          reviews.map(review => {
            const isDisabled =
              isCurrentReview(dayjs(review.date), time) &&
              review.rating === null;
            return (
              <Link
                disabled={isDisabled}
                key={review.id}
                to="/dashboard/project/$projectId/review/$reviewNumber"
                params={{
                  projectId: project.id.toString(),
                  reviewNumber: String(review.reviewNumber),
                }}>
                <ReviewItem project={project} review={review} />
              </Link>
            );
          })
        ) : (
          <p>No updates yet</p>
        )}
      </div>

      <BottomNavigation left={<BackButton native />} />
    </main>
  );
}
