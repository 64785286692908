'use client';

import { UserType } from '@/lib/global-types';

export const OPLogo = () => {
  const pathname = window.location.pathname;
  const cookieStore = window.localStorage;
  const userType = cookieStore.getItem('userType') as UserType | undefined;

  if (pathname.startsWith('/dashboard/admin') || userType === 'ADMIN')
    return (
      <svg
        width="40"
        height="24"
        viewBox="0 0 40 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.761719"
          y="0.5625"
          width="38.475"
          height="22.875"
          rx="11.4375"
          fill="white"
        />
        <rect
          x="0.761719"
          y="0.5625"
          width="38.475"
          height="22.875"
          rx="11.4375"
          stroke="white"
          strokeWidth="1.125"
        />
        <g>
          <path
            d="M7.48 12.192C7.48 15.184 9.144 16.832 11.256 16.832C13.368 16.832 15.032 15.184 15.032 12.192C15.032 9.2 13.368 7.568 11.256 7.568C9.144 7.568 7.48 9.2 7.48 12.192ZM16.696 12.192C16.696 15.696 14.6 18.208 11.256 18.208C7.912 18.208 5.816 15.696 5.816 12.192C5.816 8.688 7.912 6.192 11.256 6.192C14.6 6.192 16.696 8.688 16.696 12.192ZM18.4116 6.4H22.0436C24.8756 6.4 26.8596 7.04 26.8596 9.904C26.8596 12.784 24.8756 13.408 22.0436 13.408H20.0436V18H18.4116V6.4ZM22.1716 7.776H20.0436V11.984H22.1716C23.8836 11.984 25.1956 11.808 25.1956 9.904C25.1956 8.016 23.8836 7.776 22.1716 7.776Z"
            fill="black"
          />
        </g>
        <g>
          <path
            d="M28.304 9.112C28.304 7.32 29.64 6.096 31.304 6.096C32.96 6.096 34.296 7.32 34.296 9.112C34.296 10.888 32.96 12.104 31.304 12.104C29.64 12.104 28.304 10.888 28.304 9.112ZM28.896 9.112C28.896 10.584 29.96 11.568 31.304 11.568C32.68 11.568 33.704 10.584 33.704 9.112C33.704 7.624 32.68 6.632 31.304 6.632C29.96 6.632 28.896 7.624 28.896 9.112ZM32.048 10.592L31.4 9.376H31.256H30.792V10.592H30.136V7.4H31.112C32.048 7.4 32.616 7.568 32.616 8.392C32.616 8.744 32.448 9.056 32.04 9.24L32.776 10.592H32.048ZM31.96 8.4C31.96 8.016 31.664 7.92 31.216 7.92H30.792V8.872H31.256C31.76 8.872 31.96 8.712 31.96 8.4Z"
            fill="black"
          />
        </g>
      </svg>
    );

  return (
    <svg
      width="40"
      height="24"
      viewBox="0 0 40 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.75"
        y="0.75"
        width="38.1"
        height="22.5"
        rx="11.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M7.2798 12.192C7.2798 15.184 8.9438 16.832 11.0558 16.832C13.1678 16.832 14.8318 15.184 14.8318 12.192C14.8318 9.2 13.1678 7.568 11.0558 7.568C8.9438 7.568 7.2798 9.2 7.2798 12.192ZM16.4958 12.192C16.4958 15.696 14.3998 18.208 11.0558 18.208C7.7118 18.208 5.6158 15.696 5.6158 12.192C5.6158 8.688 7.7118 6.192 11.0558 6.192C14.3998 6.192 16.4958 8.688 16.4958 12.192ZM18.2114 6.4H21.8434C24.6754 6.4 26.6594 7.04 26.6594 9.904C26.6594 12.784 24.6754 13.408 21.8434 13.408H19.8434V18H18.2114V6.4ZM21.9714 7.776H19.8434V11.984H21.9714C23.6834 11.984 24.9954 11.808 24.9954 9.904C24.9954 8.016 23.6834 7.776 21.9714 7.776Z"
        fill="currentColor"
      />
      <path
        d="M28.1038 9.112C28.1038 7.32 29.4398 6.096 31.1038 6.096C32.7598 6.096 34.0958 7.32 34.0958 9.112C34.0958 10.888 32.7598 12.104 31.1038 12.104C29.4398 12.104 28.1038 10.888 28.1038 9.112ZM28.6958 9.112C28.6958 10.584 29.7598 11.568 31.1038 11.568C32.4798 11.568 33.5038 10.584 33.5038 9.112C33.5038 7.624 32.4798 6.632 31.1038 6.632C29.7598 6.632 28.6958 7.624 28.6958 9.112ZM31.8478 10.592L31.1998 9.376H31.0558H30.5918V10.592H29.9358V7.4H30.9118C31.8478 7.4 32.4158 7.568 32.4158 8.392C32.4158 8.744 32.2478 9.056 31.8398 9.24L32.5758 10.592H31.8478ZM31.7598 8.4C31.7598 8.016 31.4638 7.92 31.0158 7.92H30.5918V8.872H31.0558C31.5598 8.872 31.7598 8.712 31.7598 8.4Z"
        fill="currentColor"
      />
    </svg>
  );
};

OPLogo.displayName = 'OPLogo';
