import { createFileRoute, Link, redirect } from '@tanstack/react-router';

import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { useUmamiPageEvent } from '@/lib/umami';
import { isFounderNewCompleted$ } from '@/store/founder.state';

export const Route = createFileRoute('/new/founder/check-email')({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: '/new/founder' });
    }
  },
  component: function Page() {
    useUmamiPageEvent('Founder - Submitted');
    return (
      <div>
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="mt-10 md:mt-20">
            Check your inbox. <br /> It holds a magic link.
          </h1>
        </section>

        <BottomNavigation
          left={
            <Button asChild>
              <Link to="/">Close</Link>
            </Button>
          }
        />
      </div>
    );
  },
});
