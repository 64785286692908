import { createFileRoute, redirect } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { guardRoute } from '@/lib/route.utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute(
  '/dashboard/project/$projectId/review/$reviewNumber/next',
)({
  beforeLoad: () => guardRoute(['DESIGNER']),
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const review = project.reviews.find(
      review => review.reviewNumber === Number(params.reviewNumber),
    );

    if (!review) {
      toast("Couldn't find the Review");
      throw redirect({
        to: '/dashboard/project/$projectId',
        params: { projectId: project.id.toString() },
        throw: true,
        replace: true,
      });
    }

    return { project, review };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { review, project } = Route.useLoaderData();
  const navigate = Route.useNavigate();

  const [nextStepDescription, setNextStepDescription] = useState(
    review.nextStepDescription || '',
  );

  const reviewSendMutation = trpc.project.review.send.useMutation();

  return (
    <main>
      <div className="flex flex-col items-center gap-10">
        <h2>Outline your next step</h2>

        <Textarea
          placeholder="Write..."
          value={nextStepDescription}
          onChange={e => setNextStepDescription(e.target.value)}
        />
      </div>

      <BottomNavigation
        left={<BackButton disabled={reviewSendMutation.isPending} />}
        middle={
          <Button
            onClick={async () => {
              await reviewSendMutation.mutateAsync({
                reviewId: review.id,
                id: project.id,
                nextStepDescription,
              });

              toast('Review sent successfully!');
              navigate({
                to: '/dashboard/project/$projectId',
                params: { projectId: project.id.toString() },
                replace: true,
              });
            }}
            loading={reviewSendMutation.isPending}>
            Send
          </Button>
        }
      />
    </main>
  );
}
