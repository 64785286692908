import { createFileRoute, redirect } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/lib/utils/toast';
import {
  founderOnboardingState$,
  isFounderOnboardingCompleted$,
} from '@/store/founder.state';

export const Route = createFileRoute('/onboarding/founder/outcome')({
  beforeLoad: () => {
    if (!isFounderOnboardingCompleted$.peek()) {
      return redirect({ to: '/onboarding/founder' });
    }
  },
  component: function Page() {
    const project_outcome_description =
      founderOnboardingState$.project_outcome_description.get();
    const navigate = Route.useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (project_outcome_description.length < 50) {
        toast(
          "This field should be more than 50 characters. It's crucial for us to understand your needs.",
        );
        return;
      }
      navigate({
        to: '/onboarding/founder/links',
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center"
      >
        <h1 className="max-w-[320px] text-balance md:max-w-[400px]">
          {"What's your desired outcome for this project?"}
          <br />
          {
            'Help us understand your vision and why you chose us to make it happen.'
          }
        </h1>

        <div className="mt-20 flex justify-center">
          <Textarea
            onChange={e => {
              founderOnboardingState$.project_outcome_description.set(
                e.target.value,
              );
            }}
            placeholder={'Answer here..'}
            value={project_outcome_description}
            autoFocus
          />
        </div>

        <BottomNavigation
          left={<BackButton native />}
          middle={
            project_outcome_description && <Button type="submit">Next</Button>
          }
        />
      </form>
    );
  },
});
