import { createFileRoute, Link } from '@tanstack/react-router';
import { useState } from 'react';
import { z } from 'zod';

import { trpc } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { SlideContainer } from '@/components/slide-container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { zPhoneNumber } from '@/lib/utils';
import { toast } from '@/lib/utils/toast';

const schema = z.object({
  entityName: z
    .string()
    .min(2, 'Legal entity name is too short.')
    .max(50, 'Legal entity name is too long.'),
  country: z
    .string()
    .min(2, 'Country is too short.')
    .max(50, 'Country is too long.'),
  address: z
    .string()
    .min(5, 'Address is too short.')
    .max(250, 'Address is too long.'),
  phoneNumber: zPhoneNumber,
});

export const Route = createFileRoute('/dashboard/founder/company-form')({
  component: function Page() {
    const navigate = Route.useNavigate();
    // dummy state for textarea and inputs to behave normally
    const [a, setA] = useState('');
    const [b, setB] = useState('');
    const [c, setC] = useState('');
    const [d, setD] = useState('');

    const saveMutation = trpc.founder.addBillingDetails.useMutation();

    const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);

      const entityName = formData.get('entityName');
      const country = formData.get('country');
      const address = formData.get('address');
      const phoneNumber = formData.get('phoneNumber');

      const result = schema.safeParse({
        entityName,
        country,
        address,
        phoneNumber,
      });

      if (!result.success) {
        const errors = result.error.flatten().fieldErrors;
        for (const error in errors) {
          toast(errors[error as keyof z.infer<typeof schema>]);
          break;
        }
        return;
      }

      await saveMutation.mutateAsync({
        legal_name: result.data.entityName,
        company_address: result.data.address,
        country: result.data.country,
        phone: result.data.phoneNumber,
      });

      navigate({ to: '/dashboard/founder/invoice' });
    };

    return (
      <form onSubmit={onSave} className="h-full">
        <SlideContainer className="flex flex-col gap-10 text-center md:gap-20">
          <h2>
            Please share company details <br /> for our invoice.
          </h2>
          <div className="flex flex-col items-center gap-4">
            <Input
              name="entityName"
              type={'text'}
              placeholder={'Legal entity name'}
              required
              value={a}
              onChange={e => {
                setA(e.currentTarget.value);
              }}
            />
            <Input
              name="country"
              type={'text'}
              placeholder={'Country'}
              required
              value={b}
              onChange={e => {
                setB(e.currentTarget.value);
              }}
            />
            <Textarea
              name="address"
              inputMode="text"
              placeholder={'Address'}
              required
              value={c}
              onChange={e => {
                setC(e.currentTarget.value);
              }}
            />
            <Input
              name="phoneNumber"
              type={'text'}
              placeholder={'Phone number'}
              required
              value={d}
              onChange={e => {
                setD(e.currentTarget.value);
              }}
            />
          </div>
        </SlideContainer>
        <BottomNavigation
          left={
            <Button size={'sm'} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            <Button loading={saveMutation.isPending} type="submit">
              Submit
            </Button>
          }
        />
      </form>
    );
  },
});
