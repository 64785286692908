import dayjs, { Dayjs } from 'dayjs';

import { getProjectDurationMonthFromOption } from '../founder/utils';
import { Project, Review, Update } from './constants';

export const createUpdatesDatesForProject = (project: Project) => {
  const founder = project.founder;

  const projectMaxDurationMonth = getProjectDurationMonthFromOption(
    founder.selectedOption!,
  );
  const startDate = dayjs(project.startDate);
  const endDate = startDate.add(projectMaxDurationMonth, 'month');

  // 2 updates per week
  const totalUpdates = projectMaxDurationMonth * 4 * 2;
  let nextDate = getNextUpdateDate(startDate);
  // updates should be every tuesday and thursday
  const updates: Dayjs[] = [];
  for (let i = 0; i < totalUpdates; i++) {
    if (nextDate.isAfter(endDate)) {
      break;
    }

    updates.push(nextDate);
    nextDate = getNextUpdateDate(nextDate.add(1, 'day'));
  }

  return updates;
};

export const getNextUpdateDate = (date: Dayjs) => {
  const day = date.day();

  let nextDate = date.clone();
  switch (day) {
    case 0: {
      // Sunday
      nextDate = date.add(2, 'day'); // Tuesday
      break;
    }
    case 1: {
      // Monday
      nextDate = date.add(1, 'day'); // Tuesday
      break;
    }
    case 2: {
      // Tuesday
      const hour = date.hour();
      if (hour < 16 || (hour >= 16 && hour < 17)) {
        nextDate = date; // Tuesday
        break;
      }
      nextDate = date.add(2, 'day'); // Thursday
      break;
    }
    case 3: {
      // Wednesday
      nextDate = date.add(1, 'day'); // Thursday
      break;
    }
    case 4: {
      const hour = date.hour();
      if (hour < 16 || (hour >= 16 && hour < 17)) {
        nextDate = date; // Thursday
        break;
      }
      nextDate = date.add(5, 'day'); // Tuesday
      break;
    }
    case 5: {
      // Friday
      nextDate = date.add(4, 'day'); // Tuesday
      break;
    }
    case 6: {
      // Saturday
      nextDate = date.add(3, 'day'); // Tuesday
      break;
    }
    default: {
      nextDate = date;
      break;
    }
  }

  return nextDate.set('hour', 16).set('minute', 0).set('second', 0);
};

export const isCurrentUpdate = (updateDate: Dayjs, time: Dayjs) => {
  const nextUpdateStartDate = getNextUpdateDate(time);
  const updateStartDate = updateDate; // 4pm
  const updateEndDate = updateDate.add(1, 'hour'); // 5pm

  const isBetweenRateTime =
    time.isAfter(updateStartDate) && time.isBefore(updateEndDate);

  if (isBetweenRateTime) {
    return true;
  }

  if (
    time.isBefore(updateStartDate) &&
    nextUpdateStartDate.isSame(updateStartDate, 'day')
  ) {
    return true;
  }

  return false;
};

export const getCurrentUpdate = (updates: Update[], time: Dayjs) => {
  for (const update of updates) {
    const updateDate = dayjs(update.date);
    if (isCurrentUpdate(updateDate, time)) {
      return update;
    }
  }

  return null;
};

export const getLastSentUpdate = (updates: Update[]) => {
  const lastSendUpdate = updates
    .filter(u => u.status === 'SENT' || u.status === 'RATED')
    .sort((a, b) => {
      return dayjs(b.date).diff(dayjs(a.date));
    })[0];

  return lastSendUpdate;
};

export const hasLastSentUpdateWithin24Hours = (
  updates: Update[],
  time: Dayjs,
) => {
  const lastSentUpdate = getLastSentUpdate(updates);

  if (!lastSentUpdate) {
    return null;
  }

  const lastSentUpdateUpdatedAt = dayjs(lastSentUpdate.updatedAt);

  const isLessThan24Hours = time.diff(lastSentUpdateUpdatedAt, 'hour') < 24;

  if (!isLessThan24Hours) {
    return null;
  }

  return {
    startDate: lastSentUpdateUpdatedAt,
    endDate: lastSentUpdateUpdatedAt.add(24, 'hour'),
    update: lastSentUpdate,
  };
};

// ==== Reviews ====

export const createReviewDatesForProject = (project: Project) => {
  const founder = project.founder;

  const projectMaxDurationMonth = getProjectDurationMonthFromOption(
    founder.selectedOption!,
  );
  const startDate = dayjs(project.startDate);
  const endDate = startDate.add(projectMaxDurationMonth, 'month');

  // 4 reviews per week, mon, tue, wed, thu
  const totalReviews = projectMaxDurationMonth * 4 * 4;

  let nextDate = getNextReviewDate(startDate);

  // reviews should be every monday, tuesday, wednesday, thursday
  const reviews: Dayjs[] = [];

  for (let i = 0; i < totalReviews; i++) {
    if (nextDate.isAfter(endDate)) {
      break;
    }

    reviews.push(nextDate);

    const target = nextDate.set('hour', 17);
    nextDate = getNextReviewDate(target);
  }

  return reviews;
};

export const getNextReviewDate = (date: Dayjs) => {
  const day = date.day();
  // reviews should be every monday, tuesday, wednesday, thursday between 3pm and 4pm
  let nextDate = date.clone();

  const isBeforeEndTime = date.hour() < 16;

  switch (day) {
    case 0:
    case 1:
    case 2:
    case 3: {
      // Sunday
      if (isBeforeEndTime) break;
      nextDate = date.add(1, 'day'); // Monday
      break;
    }
    case 4: {
      // Thursday
      if (isBeforeEndTime) break;
      nextDate = date.add(4, 'day'); // Monday
      break;
    }
    case 5: {
      // Friday
      nextDate = date.add(3, 'day'); // Monday
      break;
    }
    case 6: {
      // Saturday
      nextDate = date.add(2, 'day'); // Monday
      break;
    }
    default: {
      nextDate = date;
      break;
    }
  }

  return nextDate.set('hour', 15).set('minute', 0).set('second', 0); // set to 3pm
};

export const isCurrentReview = (reviewDate: Dayjs, time: Dayjs) => {
  const nextReviewStartDate = getNextReviewDate(time);
  const reviewStartDate = reviewDate; // 3pm
  const reviewEndDate = reviewDate.add(1, 'hour'); // 4pm

  const isBetweenRateTime =
    time.isAfter(reviewStartDate) && time.isBefore(reviewEndDate);

  if (isBetweenRateTime) {
    return true;
  }

  if (
    time.isBefore(reviewStartDate) &&
    nextReviewStartDate.isSame(reviewStartDate, 'day')
  ) {
    return true;
  }

  return false;
};

export const getCurrentReview = (reviews: Review[], time: Dayjs) => {
  for (const review of reviews) {
    const reviewDate = dayjs(review.date);
    if (isCurrentReview(reviewDate, time)) {
      return review;
    }
  }

  return null;
};

export const getLastSentReview = (reviews: Review[]) => {
  const lastSendReview = reviews
    .filter(r => r.status === 'SENT' || r.status === 'RATED')
    .sort((a, b) => {
      return dayjs(b.date).diff(dayjs(a.date));
    })[0];

  return lastSendReview;
};

export const hasLastSentReviewWithin6Hours = (
  reviews: Review[],
  time: Dayjs,
) => {
  const lastSentReview = getLastSentReview(reviews);

  if (!lastSentReview) {
    return null;
  }

  const lastSentReviewUpdatedAt = dayjs(lastSentReview.updatedAt);

  const isLessThan6Hours = time.diff(lastSentReviewUpdatedAt, 'hour') < 6;

  if (!isLessThan6Hours) {
    return null;
  }

  return {
    startDate: lastSentReviewUpdatedAt,
    endDate: lastSentReviewUpdatedAt.add(6, 'hour'),
    review: lastSentReview,
  };
};
