import { trpc } from '@/api/trpc';
import { Designer, Founder } from '@/lib/global-types';

export function useFounderStatusUpdate(
  founderId: Founder['id'],
  cb: (status: Founder['status']) => void,
) {
  trpc.founder.onStatusUpdate.useSubscription(
    {
      id: founderId,
    },
    {
      onData: ({ data }) => cb(data),
    },
  );
}

export function useFounderAIStatusUpdate(
  founderId: Founder['id'],
  cb: (status: Founder['ai_status']) => void,
) {
  trpc.ai.onAIStatusChange.useSubscription(
    {
      founderId,
    },
    {
      onData: ({ data }) => cb(data),
    },
  );
}

export function useDesignerStatusUpdate(
  designerId: Designer['id'],
  cb: (status: string) => void,
) {
  trpc.designer.onStatusUpdate.useSubscription(
    {
      id: designerId,
    },
    {
      onData: ({ data }) => cb(data),
    },
  );
}
