import { isCurrentReview } from '@op-platform/backend/project/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { CoundownTimer } from '@/components/countdown-timer';
import { Button } from '@/components/ui/button';
import { useReviewTimesForDesigner } from '@/hooks/use-review-times';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import { Project, Review } from '@/lib/global-types';
import { cn, getRatingColor } from '@/lib/utils';

type Props = {
  review: Review;
  project: Project;
};
export const ReviewItem = ({ project, review }: Props) => {
  const time = useTimeZoneClock(project.founder.time_zone!);

  const currentReview = isCurrentReview(dayjs(review.date), time);
  const { startDate, endDate } = useReviewTimesForDesigner(
    project.founder.time_zone!,
    project.founder.time_zone!,
  );

  const content = useMemo(() => {
    return (
      <span className="flex flex-col items-center gap-2">
        {review.rating !== null && (
          <span
            className={cn(
              'size-2 rounded-full',
              getRatingColor(review.rating),
            )}></span>
        )}
        {review.reviewNumber}
      </span>
    );
  }, [review.rating, review.reviewNumber]);

  if (currentReview) {
    return (
      <CoundownTimer
        size="md"
        startDate={startDate}
        finishDate={endDate}
        timeZone={project.founder.time_zone!}>
        {content}
      </CoundownTimer>
    );
  }

  return <Button asChild>{content}</Button>;
};
