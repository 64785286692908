import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import { useState } from 'react';

import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Links } from '@/components/links';
import { Button } from '@/components/ui/button';
import {
  designerCreateState$,
  isDesignerCreateCompleted$,
} from '@/store/designer.state';

export const Route = createFileRoute('/new/designer/links')({
  beforeLoad: () => {
    if (!isDesignerCreateCompleted$.peek()) {
      return redirect({ to: '/new/designer' });
    }
  },
  component: function Page() {
    const [currentLink, setCurrentLink] = useState('');
    const links = designerCreateState$.links.get();

    return (
      <div className="text-center">
        <h1>
          Add at least one link. <br />
          X, IG, YT or WWW.
        </h1>

        <Links
          className="mt-10 w-screen md:mt-20 md:w-full"
          currentLink={currentLink}
          setCurrentLink={setCurrentLink}
          linkList={links}
          setLinkList={links => designerCreateState$.links.set(links)}
        />

        <BottomNavigation
          left={
            <Button size="sm" asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
          middle={
            links.length > 0 && (
              <Button asChild>
                <Link to="/new/designer/time">Next</Link>
              </Button>
            )
          }
        />
      </div>
    );
  },
});
