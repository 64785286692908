import { createFileRoute, redirect } from '@tanstack/react-router';
import umami from '@umami/node';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useUmamiPageEvent } from '@/lib/umami';
import {
  FounderNewState,
  founderNewState$,
  isFounderNewCompleted$,
} from '@/store/founder.state';

export const Route = createFileRoute('/new/founder/time')({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: '/new/founder' });
    }
  },
  component: function Page() {
    const kickOffDate = founderNewState$.estimated_start_date.get();
    const navigate = Route.useNavigate();
    useUmamiPageEvent('Founder - When would you like to begin?');

    return (
      <div>
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="mt-10">When would you like to begin?</h1>

          <ToggleGroup
            className="mt-10 md:mt-20"
            type="single"
            defaultValue={kickOffDate!}
            onValueChange={value => {
              if (value) {
                founderNewState$.estimated_start_date.set(
                  value as FounderNewState['estimated_start_date'],
                );
                umami.track('Founder Answer - When would you like to begin?', {
                  KickoffDate: value,
                });
                navigate({ to: '/new/founder/email' });
              }
            }}
          >
            <ToggleGroupItem value="NEXT_WEEK">Next Week</ToggleGroupItem>
            <ToggleGroupItem value="NEXT_MONTH">Next Month</ToggleGroupItem>
            <ToggleGroupItem value="LATER">Later</ToggleGroupItem>
          </ToggleGroup>
        </section>

        <BottomNavigation left={<BackButton native />} />
      </div>
    );
  },
});
