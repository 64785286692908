import { createFileRoute } from '@tanstack/react-router';

import { trpcClientUtils } from '@/api/trpc';
import { Spinner } from '@/components/spinner';
import { SiteReport } from '@/features/reports/views/site-reports';

export const Route = createFileRoute('/dashboard/admin/site-reports')({
  loader: async () => {
    return {
      stats: await trpcClientUtils.siteReports.getWebsiteStats.fetch(),
      klavioStats: await trpcClientUtils.siteReports.getKlavioReport.fetch(),
      platformInsights:
        await trpcClientUtils.siteReports.getPlatformInsights.fetch(),
      socialStats: await trpcClientUtils.siteReports.getSocialStats.fetch(),
    };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: SiteReport,
});

export const SiteReportRoute = Route;
