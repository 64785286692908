import { Attachment, MessageText, useMessageContext } from 'stream-chat-react';

import { cn } from '@/lib/utils';

export const CustomMessage = () => {
  const { isMyMessage, message } = useMessageContext();

  const user = message.user;

  const name = user?.role === 'admin' ? 'OpenPurpose' : user?.name;

  return (
    <div
      className={cn('op-chat-ui-messages', {
        'op-chat-ui-messages--other': !isMyMessage(),
        'op-chat-ui-messages--mine': isMyMessage(),
      })}
      data-message-id={message.id}>
      <div className="flex flex-col gap-1">
        <span
          className={cn({
            'text-left': !isMyMessage(),
            'text-right': isMyMessage(),
          })}>
          {name}
        </span>
        <div
          className={cn('rounded-2xl bg-red p-4', {
            'bg-black/20': !isMyMessage(),
            'border border-black/20 bg-white': isMyMessage(),
          })}>
          <MessageText />
          {message.attachments && (
            <Attachment attachments={message.attachments} />
          )}
        </div>
      </div>
    </div>
  );
};
