import { observable } from '@legendapp/state';

import { TRPCRouterInput, TRPCRouterOutput } from '@/lib/global-types';

export type MonthlyEmailPreference = 'yes' | 'no' | undefined;
type FounderCreateInput = TRPCRouterInput['founder']['create'];

export type FounderNewState = {
  email: string;
  requested_option: {
    plan: FounderCreateInput['requested_option']['plan'];
    services: TRPCRouterOutput['misc']['services'];
  };
  stage: FounderCreateInput['stage'] | null;
  estimated_start_date: FounderCreateInput['estimated_start_date'] | null;
  showDuration: boolean;
  requireDurationServiceTypes: TRPCRouterOutput['misc']['services'][number]['type'][];
  showServiceHighlight: boolean;
};
export const founderNewState$ = observable<FounderNewState>({
  email: '',
  requested_option: {
    plan: undefined,
    services: [],
  },
  stage: null,
  showDuration: false,
  requireDurationServiceTypes: [],
  showServiceHighlight: false,
  estimated_start_date: null,
});

export const isFounderNewCompleted$ = observable(() => {
  return (
    founderNewState$.stage.get() !== null ||
    founderNewState$.requested_option.get().services.length > 0 ||
    founderNewState$.requested_option.get().plan !== undefined ||
    founderNewState$.estimated_start_date.get() !== null
  );
});

export type FounderOnboardingState = {
  company_name: string;
  name: string;
  project_outcome_description: string;
  links: string[];
  time_zone: string | null;
  profile_picture: null;
  is_newsletter_allowed: MonthlyEmailPreference;
  timezone_label: string;
};

export const founderOnboardingState$ = observable<FounderOnboardingState>({
  company_name: '',
  name: '',
  project_outcome_description: '',
  links: [],
  is_newsletter_allowed: undefined,
  profile_picture: null,
  time_zone: null,
  timezone_label: '',
});

export const isFounderOnboardingCompleted$ = observable(() => {
  return (
    founderOnboardingState$.company_name.get() !== '' ||
    founderOnboardingState$.name.get() !== '' ||
    founderOnboardingState$.project_outcome_description.get() !== '' ||
    founderOnboardingState$.links.get().length > 0 ||
    founderOnboardingState$.time_zone.get() !== null ||
    founderOnboardingState$.is_newsletter_allowed.get() !== undefined
  );
});
