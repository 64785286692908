import dayjs from 'dayjs';
import timezoneplugin from 'dayjs/plugin/timezone';
import utcplugin from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';

import { SYSTEM_TIME } from '@/lib/constants';
import { unFormatTimezone } from '@/lib/utils';

dayjs.extend(utcplugin);
dayjs.extend(timezoneplugin);

const today = SYSTEM_TIME;

export const useTimeZoneClock = (timeZone: string) => {
  const [time, setTime] = useState(today().tz(unFormatTimezone(timeZone)));

  useEffect(() => {
    const interval = setInterval(
      () => setTime(prev => prev.add(1, 'second')),
      1000,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return time;
};
