import { useState } from 'react';

import { CalcomEmbed } from '@/components/calcom-embed';
import { SlideContainer } from '@/components/slide-container';
import { Button } from '@/components/ui/button';
import { UserType } from '@/lib/global-types';

type Props = {
  name: string;
  email: string;
  userType: Exclude<UserType, 'ADMIN'>;
  onSuccess?: () => void;
};
export const IntroCallRequest = ({
  name,
  email,
  userType,
  onSuccess,
}: Props) => {
  const [step, setStep] = useState<'review' | 'bookACall'>('review');

  const direction = step === 'review' ? 'forwards' : 'backwards';

  return (
    <SlideContainer
      className="flex flex-col items-center gap-10 md:gap-20"
      animationKey={step}
      direction={direction}
    >
      <p className="flex text-center">
        Welcome back, {name}! <br />
        We look forward to meeting you.
      </p>

      {step === 'review' && (
        <Button size="lg" onClick={() => setStep('bookACall')}>
          Book a call
        </Button>
      )}
      {step === 'bookACall' && (
        <div className="min-w-[380px] pb-40 md:min-w-[480px]">
          <CalcomEmbed
            name={name}
            email={email}
            userType={userType}
            callType="INTRO_CALL"
            onSuccess={onSuccess}
          />
        </div>
      )}
    </SlideContainer>
  );
};
