import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { FormEvent, useMemo, useState } from 'react';

import { trpc } from '@/api/trpc';
import { FadeContainer } from '@/components/fade-container';
import { OPLogo } from '@/components/op-logo';
import { SlideContainer } from '@/components/slide-container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { HOST } from '@/lib/constants';
import { TRPCRouterOutput } from '@/lib/global-types';
import { validateEmail } from '@/lib/utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute('/login')({
  beforeLoad: () => {
    const cookieStore = window.localStorage;

    const accessToken = cookieStore.getItem('accessToken') as
      | TRPCRouterOutput['auth']['loginSSO']['user_type']
      | undefined;

    if (!accessToken) {
      return;
    }

    return redirect({ to: '/dashboard' });
  },
  component: function Page() {
    const [email, setEmail] = useState('');

    const isEmailValid = useMemo(() => validateEmail(email)?.length, [email]);

    const loginMutation = trpc.auth.loginSSO.useMutation();

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isEmailValid) {
        toast('Email is invalid.');
        return;
      }

      loginMutation.mutate({
        email,
        dev_base_url: HOST,
      });
    };

    return (
      <div className="h-screen">
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
          <Link to="/">
            <OPLogo />
          </Link>
        </header>
        <div className="h-full px-4 pb-[172px] pt-[144px] md:pt-[104px]">
          <form className="h-full" onSubmit={onSubmit}>
            <SlideContainer
              animationKey={loginMutation.isSuccess ? 'success' : 'form'}
              className="flex h-full flex-col items-center justify-center gap-4"
            >
              {!loginMutation.isSuccess ? (
                <Input
                  autoFocus
                  type="email"
                  required
                  placeholder="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              ) : (
                <p>Check your email for the sign in link</p>
              )}
            </SlideContainer>

            <div className="fixed bottom-5 left-0 right-0 z-10 flex items-center justify-center">
              {!loginMutation.isSuccess && email.length > 0 && (
                <FadeContainer animationKey="submit">
                  <Button type="submit" loading={loginMutation.isPending}>
                    Login
                  </Button>
                </FadeContainer>
              )}
              {loginMutation.isSuccess && (
                <motion.div
                  key="back"
                  layout="position"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                >
                  <Button asChild>
                    <Link to="/">Close</Link>
                  </Button>
                </motion.div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  },
});
