'use client';

import { useClipboard } from '@/hooks/use-clipboard';
import { cn } from '@/lib/utils';
import { toast } from '@/lib/utils/toast';

interface Props {
  className?: string;
  text: string;
  email: string;
}

export const CopyEmailButton = ({ className, text, email }: Props) => {
  const [, copy] = useClipboard();

  return (
    <button
      className={cn(className)}
      onClick={async () => {
        try {
          await copy(email);
          toast(<div>Email copied.</div>);
        } catch (error) {
          if (error instanceof Error) toast(error.message);
        }
      }}>
      {text}
    </button>
  );
};
