import { motion } from 'framer-motion';
import React from 'react';
import { PropsWithChildren } from 'react';

export function FadeSection({ children }: PropsWithChildren) {
  const childrenArray = React.Children.toArray(children);

  return childrenArray.map((child, index) => (
    <motion.div
      key={index}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{
        ease: 'easeInOut',
        duration: 0.2,
        delay: index * 0.1,
      }}
    >
      {child}
    </motion.div>
  ));
}
