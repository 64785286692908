import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import umami from '@umami/node';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { ServiceType } from '@/lib/global-types';
import { useUmamiPageEvent } from '@/lib/umami';
import {
  founderNewState$,
  isFounderNewCompleted$,
} from '@/store/founder.state';

export const Route = createFileRoute('/new/founder/packages')({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: '/new/founder' });
    }
  },
  loader: () => trpcClientUtils.misc.services.ensureData(),
  staleTime: Infinity,
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),

  component: function Page() {
    const services = Route.useLoaderData();
    const founderServices = founderNewState$.requested_option.services.get()!;

    useUmamiPageEvent('Founder - Package Selector');

    return (
      <div>
        <section className="flex flex-col items-center justify-center text-center">
          <h1 className="mt-10">
            Pick the service you need. <br />
            You can select more than one.
          </h1>

          <ToggleGroup
            className="mt-10 md:mt-20"
            type="multiple"
            value={founderServices.map(service => service.type)}
            onValueChange={value => {
              if (value) {
                const order: ServiceType[] = ['BRAND', 'WEBSITE', 'PRODUCT'];
                const selectedServices = services
                  .filter(
                    service =>
                      value.includes(service.type) && service.size === 'S',
                  )
                  .sort(
                    (a, b) => order.indexOf(a.type) - order.indexOf(b.type),
                  );

                founderNewState$.requested_option.plan.set(undefined);
                founderNewState$.requireDurationServiceTypes.set(
                  selectedServices.map(s => s.type),
                );

                founderNewState$.requested_option.services.set(
                  selectedServices,
                );
              }
            }}
          >
            <ToggleGroupItem value="BRAND">Brand</ToggleGroupItem>
            <ToggleGroupItem value="WEBSITE">Website</ToggleGroupItem>
            <ToggleGroupItem value="PRODUCT">Product</ToggleGroupItem>
          </ToggleGroup>
        </section>

        <BottomNavigation
          left={<BackButton native />}
          middle={
            founderServices.length > 0 && (
              <Button
                asChild
                onClick={() =>
                  umami.track('Founder Answer - Plan Selector', {
                    Services: founderServices
                      .map(service => `${service.type} - ${service.size}`)
                      .join(', '),
                  })
                }
              >
                <Link to="/new/founder/packages/durations">Next</Link>
              </Button>
            )
          }
        />
      </div>
    );
  },
});
