import { createFileRoute, redirect } from '@tanstack/react-router';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { LinkButton } from '@/components/link-button';
import { Button } from '@/components/ui/button';
import { cn, getRatingColor, getUserTypeFromLocalStorage } from '@/lib/utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute(
  '/dashboard/project/$projectId/update/$updateNumber/_$updateNumber/',
)({
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const update = project.updates.find(
      update => update.updateNumber === Number(params.updateNumber),
    );

    if (!update) {
      toast("Couldn't find the update");
      throw redirect({
        to: '/dashboard/project/$projectId',
        params: { projectId: project.id.toString() },
        throw: true,
        replace: true,
      });
    }

    return { project, update };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { update, project } = Route.useLoaderData();
  const { isFounder, isAdmin, isDesigner } = getUserTypeFromLocalStorage();

  const needToRate = update.rating === null && isFounder;

  return (
    <>
      <div className="flex flex-col items-center gap-20 pb-40">
        <div className="flex flex-col items-center gap-10">
          <LinkButton to="..">
            <Button
              className={cn({
                'border-gray-200': update.rating === null,
              })}>
              <span className="flex flex-col items-center gap-2">
                {update.rating !== null && (
                  <span
                    className={cn(
                      'size-2 rounded-full',
                      getRatingColor(update.rating),
                    )}></span>
                )}
                <span>{update.updateNumber}</span>
              </span>
            </Button>
          </LinkButton>
          <p className="max-w-[450px] text-center">{update.description}</p>
        </div>

        {update.feedback && (
          <div className="flex flex-col items-center gap-6">
            <p>{isAdmin || isDesigner ? 'Founder' : 'Your'} Feedback</p>
            <p className="max-w-[450px] text-center">{update.feedback}</p>
          </div>
        )}

        <div className="flex flex-col items-center gap-10">
          {update.assets?.map((imageSource, index) => (
            <div
              key={imageSource}
              className={cn(
                'group relative flex aspect-video w-[90vw] max-w-[960px] items-center justify-center overflow-hidden rounded-2xl ring-1 ring-gray-200',
              )}>
              <img
                height={540}
                width={960}
                src={imageSource}
                alt={`preview of image ${index}`}
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center gap-6">
          <p>Next steps</p>
          <p className="max-w-[450px] text-center">
            {update.nextStepDescription}
          </p>
        </div>
      </div>

      <BottomNavigation
        left={<BackButton native />}
        middle={
          needToRate && (
            <LinkButton
              to="/dashboard/project/$projectId/update/$updateNumber/rate"
              params={{
                projectId: project.id.toString(),
                updateNumber: String(update.updateNumber),
              }}>
              Rate
            </LinkButton>
          )
        }
      />
    </>
  );
}
