import { PLAN_WITH_SERVICES } from '@op-platform/backend/founder';
import { createFileRoute, Link } from '@tanstack/react-router';

import { trpcClientUtils } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { FadeScaleContainer } from '@/components/fade-scale-container';
import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from '@/components/feature-groups';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';
import { Option } from '@/lib/global-types';
import {
  capitalize,
  convertServicesForUI,
  isBlankOption,
  mountToSize,
  priceFormatter,
  sizeToMonth,
  sizeToMountCount,
} from '@/lib/utils';

export const Route = createFileRoute('/dashboard/founder/proposals_/included')({
  loader: async () => {
    const founder = await trpcClientUtils.founder.me.ensureData();

    return { founder };
  },
  component: function Page() {
    const { founder } = Route.useLoaderData();

    const options = founder.optionsPack.options;

    const requestedOption = options.find(o => o.type === 'REQUESTED')!;
    const recommendedOption = options.find(o => o.type === 'RECOMMENDED')!;
    const suggestedOption = options.find(o => o.type === 'SUGGESTED')!;

    const getServicesFromOption = (option: Option) => {
      if (option.plan)
        return convertServicesForUI(PLAN_WITH_SERVICES[option.plan]);
      else return convertServicesForUI(option.services);
    };

    const renderIncludes = (prop: Option) => {
      const allServices = getServicesFromOption(prop);
      return allServices.map(service => {
        const serviceDuration = sizeToMountCount(service.size);
        const dots = [...Array(serviceDuration)].map((_, i) => (
          <FeatureDot key={i} />
        ));

        return (
          <div
            key={`${service.type}-${service.size}`}
            className="mb-20 flex flex-col items-center">
            <FeatureContainer>
              <FeatureGroup className="w-7">
                {service.type.at(0)?.toUpperCase()}
              </FeatureGroup>
              <FeatureGroup className="gap-2">{dots}</FeatureGroup>
            </FeatureContainer>

            <p className="mt-10 capitalize">
              {service.type.toLocaleLowerCase()}
            </p>
            <p className="mt-2">{sizeToMonth(service.size)}</p>
            <p>{priceFormatter.format(service.price)}</p>

            <p className="mt-6">Deliverables</p>
            <p>{service.description}</p>
          </div>
        );
      });
    };

    const renderHeader = (prop: Option) => {
      const allServices = getServicesFromOption(prop);
      const maxDuration = allServices.reduce(
        (acc, service) => Math.max(acc, sizeToMountCount(service.size)),
        0,
      );

      const dots = [...Array(maxDuration)].map((_, i) => (
        <FeatureDot key={i} />
      ));
      const price = allServices.reduce(
        (acc, service) => acc + service.price,
        0,
      );

      return (
        <div className="mt-10 flex flex-col items-center">
          <FeatureContainer>
            {prop.plan ? (
              <FeatureGroup>
                <img
                  src={`/${prop.plan.toLocaleLowerCase()}.png`}
                  alt={prop.plan as string}
                  className="mx-1 mb-1 size-4 rounded-full"
                  width={16}
                  height={16}
                />
              </FeatureGroup>
            ) : (
              allServices.map(service => (
                <FeatureGroup
                  key={`${service.type}-${service.size}`}
                  className="w-7">
                  {service.type.at(0)?.toUpperCase()}
                </FeatureGroup>
              ))
            )}
            {prop.plan ? null : (
              <FeatureGroup className="gap-2">{dots}</FeatureGroup>
            )}
          </FeatureContainer>

          <p className="mt-10">
            {allServices.map(service => capitalize(service.type)).join(' + ')}
          </p>
          <p>{sizeToMonth(mountToSize(maxDuration)!)}</p>
          <p>{priceFormatter.format(price)}</p>
        </div>
      );
    };

    return (
      <>
        <FadeScaleContainer className="flex w-screen flex-col pb-20 md:flex-row">
          <section className="mx-auto flex flex-1 flex-col items-center">
            <h1>Request</h1>
            {renderHeader(requestedOption)}
            <p className="my-20">Includes</p>
            {renderIncludes(requestedOption)}
          </section>

          {!isBlankOption(recommendedOption) && (
            <section className="mx-auto flex flex-1 flex-col items-center">
              <h1>Recommendation</h1>
              {renderHeader(recommendedOption)}
              <p className="my-20">Includes</p>
              {renderIncludes(recommendedOption)}
            </section>
          )}
          {!isBlankOption(suggestedOption) && (
            <section className="mx-auto flex flex-1 flex-col items-center">
              <h1>Suggestion</h1>
              {renderHeader(suggestedOption)}
              <p className="my-20">Includes</p>
              {renderIncludes(suggestedOption)}
            </section>
          )}
        </FadeScaleContainer>
        <BottomNavigation
          middle={
            <Button size={'sm'} asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </>
    );
  },
});
