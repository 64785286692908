import { createFileRoute, redirect } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { guardRoute } from '@/lib/route.utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute(
  '/dashboard/project/$projectId/update/$updateNumber/next',
)({
  beforeLoad: () => guardRoute(['ADMIN', 'DESIGNER']),
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const update = project.updates.find(
      update => update.updateNumber === Number(params.updateNumber),
    );

    if (!update) {
      toast("Couldn't find the update");
      throw redirect({
        to: '/dashboard/project/$projectId',
        params: { projectId: project.id.toString() },
        throw: true,
        replace: true,
      });
    }

    return { project, update };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { update, project } = Route.useLoaderData();
  const navigate = Route.useNavigate();

  const [nextStepDescription, setNextStepDescription] = useState(
    update.nextStepDescription || '',
  );

  const updateSendMutation = trpc.project.update.send.useMutation();

  return (
    <main>
      <div className="flex flex-col items-center gap-10">
        <h2>Outline your next step</h2>

        <Textarea
          placeholder="Write..."
          value={nextStepDescription}
          onChange={e => setNextStepDescription(e.target.value)}
        />
      </div>

      <BottomNavigation
        left={
          <BackButton
            disabled={updateSendMutation.isPending}
            /*    to={`/dashboard/admin/founders/$founderId/updates/$updateId/preview`}
          params={{ founderId }} */
          />
        }
        middle={
          <Button
            onClick={async () => {
              await updateSendMutation.mutateAsync({
                updateId: update.id,
                id: project.id,
                nextStepDescription,
              });

              toast('Update sent successfully!');
              navigate({
                to: '/dashboard/project/$projectId',
                params: { projectId: project.id.toString() },
                replace: true,
              });
            }}
            loading={updateSendMutation.isPending}>
            Send
          </Button>
        }
      />
    </main>
  );
}
