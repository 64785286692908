import { SYSTEM_TIME as _SYSTEM_TIME } from '@op-platform/backend/project';
import dayjs from 'dayjs';

import { Founder, ServiceType } from './global-types';

export const HOST = import.meta.env.VITE_HOST;
export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const CALCOM_URL = import.meta.env.VITE_CALCOM_URL;
export const IS_DEV = import.meta.env.DEV;

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const SERVICE_SIZE_VALUES = {
  notSure: 0,
  S: 1,
  M: 2,
  L: 3,
};

export type PackageDetail = {
  description: string;
  duration: string;
  preSeedPrice: number;
  seedPrice: number;
  seriesAPlusPrice: number;
};

export type Package = {
  name: ServiceType;
  details: {
    small: PackageDetail;
    medium: PackageDetail;
    large: PackageDetail;
  };
};

export const TIME_TEST_KEY = 'debug_time';
export const SYSTEM_TIME = () => {
  if (!IS_DEV) return _SYSTEM_TIME();

  const debugDate = localStorage.getItem(TIME_TEST_KEY);

  return debugDate ? dayjs(debugDate) : _SYSTEM_TIME();
};

export const founderGrayStatuses: Founder['status'][] = ['ONBOARDED'];

export const founderHalfYellowStatuses: Founder['status'][] = [
  'INTRO_CALL_REQUEST_SENT',
  'INTRO_CALL_BOOKED',
  'INTRO_CALL_STARTED',
  'INTRO_CALL_ENDED',
];

export const founderYellowStatuses: Founder['status'][] = [
  'OPTIONS_CREATED',
  'OPTIONS_SENT',
  'OPTIONS_CONFIRMED',

  'INVOICE_SENT',
  'PAYMENT_MADE',

  'KICKOFF_CALL_REQUEST_SENT',
  'KICKOFF_CALL_BOOKED',
  'KICKOFF_CALL_STARTED',
  'KICKOFF_CALL_ENDED',
];

export const founderGreenStatuses: Founder['status'][] = ['PROJECT_STARTED']; //["projectStarted"];

export const founderRedStatuses: Founder['status'][] = ['REJECTED'];
