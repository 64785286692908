import '../chat.scss';

import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { StreamChat } from 'stream-chat';
import { Channel, Chat, MessageInput, MessageList } from 'stream-chat-react';

import { trpcVanilla } from '@/api/trpc';
import { Spinner } from '@/components/spinner';
import { getUserTypeFromLocalStorage } from '@/lib/utils';

import { chatState$ } from '../chat.state';
import { CustomMessage } from '../components/custom-message';

export const chatClient = StreamChat.getInstance('5atwbpuj6b97');

export const ChatView = () => {
  const channelId = chatState$.channelId.get();

  if (!channelId) {
    throw new Error('Channel ID not provided! Did you forget to set it?');
  }
  const { type, email: userEmail } = getUserTypeFromLocalStorage();

  const channelConnectionQuery = useQuery({
    retry: 1,
    staleTime: 0,
    gcTime: 0,
    queryKey: ['channel', channelId, type],
    queryFn: async () => {
      switch (type) {
        case 'ADMIN':
        case 'FOUNDER': {
          const { chatToken, user } = await trpcVanilla.chat.user.query({
            email: userEmail!,
          });
          await chatClient.connectUser(user, chatToken);

          const channel = await chatClient.channel('messaging', channelId);

          await channel.watch();
          return channel;
        }

        default: {
          throw new Error('Unsupported user type');
        }
      }
    },
  });

  // Disconnect chat client on unmount
  useEffect(() => {
    return () => {
      chatClient.disconnectUser();
      chatState$.channelId.set(null);
    };
  }, []);

  if (channelConnectionQuery.isPending || !channelConnectionQuery.data) {
    return <Spinner />;
  }

  const channel = channelConnectionQuery.data;

  return (
    <Chat client={chatClient}>
      <Channel channel={channel}>
        <MessageList Message={CustomMessage} />
        <MessageInput />
      </Channel>
    </Chat>
  );
};
