import { redirect } from '@tanstack/react-router';

import { UserType } from '@/lib/global-types';
import { toast } from '@/lib/utils/toast';

export const guardRoute = (allowedUserTypes: UserType[]) => {
  const cookieStore = window.localStorage;

  const userType = cookieStore.getItem('userType') as UserType | undefined;

  if (!userType || !allowedUserTypes.includes(userType)) {
    toast('You are not authorized to access this page.');
    return redirect({ to: '/login' });
  }
};
